function o(n) {
  return n.search.substring(1).split("&").map((e) => e.split("=")).reduce((e, [t, r]) => (e[decodeURIComponent(t)] = decodeURIComponent(r), e), {});
}
function u(n) {
  return Object.keys(n).filter((e) => n[e] !== void 0).reduce(
    (e, t) => {
      const r = n[t];
      return r ? [
        ...e,
        `${encodeURIComponent(t)}=${encodeURIComponent(r)}`
      ] : e;
    },
    []
  ).join("&");
}
export {
  u as buildQueryString,
  o as parseQueryString
};
