import { IntergammaToastContainer } from "@intergamma/global-styling"
import { Footer } from "components/Footer"
import { Header } from "components/Header"
import { Main } from "components/Main"
import { Page } from "components/Page"
import { lazy } from "react"

const Rental = lazy(() =>
  import(/* webpackChunkName: "rental-reservation" */ "./components/Rental").then((module) => ({
    default: module.Rental,
  }))
)

export function RentalPage() {
  return (
    <Page moduleName="rental">
      <Header />
      <IntergammaToastContainer />
      <Main>
        <Rental />
      </Main>
      <Footer />
    </Page>
  )
}
