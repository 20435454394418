import { useQuery } from "@tanstack/react-query"
import { api } from "api"
import type { AddShippingInfoDispatchEventData } from "api/generated/checkout"
import type { AxiosRequestConfig } from "axios"

const QUERY_KEY = "addshippinginfo"

type TrackingAddShippingInfoParams = {
  shippingTier?: string
}

export function useTrackingAddShippingInfo(params: TrackingAddShippingInfoParams) {
  return useQuery({
    enabled: !!params.shippingTier,
    queryKey: [QUERY_KEY, params.shippingTier],
    queryFn: ({ signal }) => fetchAddShippingInfo({ params, signal }),
  })
}

async function fetchAddShippingInfo(config: AxiosRequestConfig) {
  const response = await api.get<AddShippingInfoDispatchEventData>("/api/data/addshippinginfo", config)
  return response.data
}
