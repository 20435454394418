import { HOME_DELIVERY, STORE_DELIVERY } from "config/constants"
import type { TFunction } from "i18next"
import type { ZodTypeAny } from "zod"
import { z } from "zod"
import type { AdaptedShipment, IFullCheckoutFormValues } from "../types"

export function schema(
  values: IFullCheckoutFormValues["delivery"],
  t: TFunction<["checkout" | "checkout-common"]>,
  shipments: AdaptedShipment[]
) {
  return z.object({
    type: type(t),
    shipments: z.tuple(
      // @fixme Fix "as unknown" or rewrite selectedShift using .refine/.superRefine
      shipments.map((shipment) =>
        z.object({
          id: id(t),
          ...(shipment.availableShifts.length > 0 && {
            selectedShift: selectedShift(t),
          }),
          ...(values?.type === STORE_DELIVERY && {
            pickupStoreUid: pickupStoreUid(t),
            pickupStoreName: pickupStoreName(t),
          }),
        })
      ) as unknown as [ZodTypeAny, ...ZodTypeAny[]]
    ),
  })
}

function type(_t: TFunction<["checkout-common" | "checkout"]>) {
  return z.literal(HOME_DELIVERY).or(z.literal(STORE_DELIVERY))
}

function id(t: TFunction<["checkout-common" | "checkout"]>) {
  return z.string().min(1, t("checkout-common:ChooseAStore"))
}

function selectedShift(_t: TFunction<["checkout-common" | "checkout"]>) {
  return z.string()
}

function pickupStoreUid(t: TFunction<["checkout-common" | "checkout"]>) {
  return z
    .string({ required_error: t("checkout-common:ChooseAStore") })
    .min(1, t("checkout-common:ChooseAStore"))
    .refine((value) => !value.includes("DEFAULT_STORE"), { message: t("checkout-common:ChooseAStore") })
}

function pickupStoreName(t: TFunction<["checkout-common" | "checkout"]>) {
  return z.string({ required_error: t("checkout-common:ChooseAStore") }).min(1, t("checkout-common:ChooseAStore"))
}
