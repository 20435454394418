export { bePostalCodeRegex, emailRegex, nlPostalCodeRegex, unsignedIntegerRegex } from "@intergamma/regex"

// Extracted from @intergamma/regex to use a negating regex (by using `^`)
export const forbiddenCharactersRegex = /^[^\p{C}<>/="()]*$/u
export const forbiddenCharactersStreetCityRegex = /^[^\p{C}<>="$]*$/u
export const streetNumberRegex = /^(0[1-9][0-9]{0,253}|[1-9][0-9]{0,254})$/
export const allowedCharactersEmailRegex = /[a-zA-Z0-9.@!#$%&'*+\-=?^_`{|}~]/g

export function getInvalidCharactersInEmail(email: string) {
  const invalid = email.split("").reduce((rejected, current) => {
    if (!current.match(allowedCharactersEmailRegex)) {
      rejected.add(current)
    }
    return rejected
  }, new Set<string>())

  return Array.from(invalid)
}
