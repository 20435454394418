import { MainLegalFooter } from "@intergamma/legal-footer"
import { withTranslation } from "react-i18next"

const LegalFooter = withTranslation("ig-legal-footer")(MainLegalFooter)

export function Footer() {
  return (
    <footer>
      <LegalFooter withSocials={false} withAppPromotion={false} />
    </footer>
  )
}
