import type { MappedCartResponse } from "features/cart/types"
import { produce } from "immer"

export function getCartWithAdjustedQuantity(
  cart: MappedCartResponse | undefined,
  params: { sku: string; desiredQuantity: number }
) {
  if (!cart) {
    return undefined
  }

  const entryIndex = cart.entries.findIndex((entry) => entry.sku === params.sku)

  if (entryIndex < 0) {
    throw new Error("Could not find entry")
  }

  return produce(cart, (draft) => {
    draft.entries[entryIndex].quantity = params.desiredQuantity
    return draft
  })
}
