import { useAuthenticate } from "@intergamma/account"
import {
  BaseNavigation,
  CartIconNavigation,
  FavoritesIconNavigation,
  HeaderAlerts,
  HeaderContainer,
  IconsNavigation,
  LanguageSelector,
  LoadingHeader,
  MainSearch,
  StoreLocatorDropdown,
  TopNavigation,
  UserIconNavigation,
  useAlertsQuery,
  useCategoriesQuery,
  useChangeStoreMutation,
  useNearbyStoresQuery,
} from "@intergamma/header"
import { useCartQuery } from "api/cart"
import { useCurrentCustomerQuery } from "api/current-customer"
import { useSessionDataQuery } from "api/myaccount/session-data"
import { useNumberOfProductsQuery } from "api/number-of-products"
import { getPathnameWithLanguageCode } from "helpers/i18n"
import { useTranslation } from "react-i18next"
import { useLocation } from "react-router-dom"
import { toast } from "react-toastify"

export function MainHeader() {
  const { t } = useTranslation("ig-header")
  const currentCustomerQuery = useCurrentCustomerQuery()
  const { alerts, onDismissAlert } = useAlertsQuery()
  const numberOfProductsQuery = useNumberOfProductsQuery()
  const cartQuery = useCartQuery({ enabled: false })
  const { categories, onSelect } = useCategoriesQuery()
  const { authenticate, error, isLoading } = useAuthenticate()
  const changeStoreMutation = useChangeStoreMutation()
  const sessionDataQuery = useSessionDataQuery()
  const nearbyStoresQuery = useNearbyStoresQuery(sessionDataQuery.data?.preferredStore ?? undefined)
  const { pathname } = useLocation()

  const alternateURL = {
    nl: getPathnameWithLanguageCode(pathname, "nl-BE"),
    fr: getPathnameWithLanguageCode(pathname, "fr-BE"),
  }

  if (sessionDataQuery.isLoading) {
    return <LoadingHeader />
  }

  return (
    <header>
      <HeaderContainer>
        <BaseNavigation
          store={sessionDataQuery.data?.preferredStore ?? undefined}
          categories={categories}
          onSelect={onSelect}
        />
        <MainSearch />

        <TopNavigation>
          <LanguageSelector alternateURL={alternateURL} />
          <StoreLocatorDropdown
            isLoadingNearbyStores={nearbyStoresQuery.isLoading}
            nearbyStores={nearbyStoresQuery.data ?? []}
            store={sessionDataQuery.data?.preferredStore ?? undefined}
            onSaveStore={changeStoreMutation.mutateAsync}
          />
        </TopNavigation>

        <IconsNavigation>
          <UserIconNavigation
            user={sessionDataQuery.data}
            isLoading={isLoading}
            onLogin={async (body) => {
              await authenticate(body)
              await Promise.all([cartQuery.refetch(), currentCustomerQuery.refetch(), sessionDataQuery.refetch()])
              toast.success(t("loginSuccess"))
            }}
            errorMessage={error}
          />
          <FavoritesIconNavigation />
          <CartIconNavigation numberOfProducts={numberOfProductsQuery.data ?? 0} />
        </IconsNavigation>
      </HeaderContainer>
      <HeaderAlerts alerts={alerts} onDismissAlert={onDismissAlert} />
    </header>
  )
}
