import { useQuery } from "@tanstack/react-query"
import { api } from "api"
import type { ToPaymentProviderDispatchEventData } from "api/generated/checkout"
import type { AxiosRequestConfig } from "axios"

const QUERY_KEY = "topaymentprovider"

type TrackingToPaymentProviderParams = {
  shippingTierCustom?: string
  paymentTypeCustom?: string
}

export function useTrackingToPaymentProvider(params: TrackingToPaymentProviderParams) {
  return useQuery({
    enabled: !!params.shippingTierCustom && !!params.paymentTypeCustom,
    queryKey: [QUERY_KEY, params.shippingTierCustom, params.paymentTypeCustom],
    queryFn: ({ signal }) => fetchToPaymentProvider({ params, signal }),
  })
}

async function fetchToPaymentProvider(config: AxiosRequestConfig) {
  const response = await api.get<ToPaymentProviderDispatchEventData>("/api/data/topaymentprovider", config)
  return response.data
}
