import type { TFunction } from "i18next"
import { z } from "zod"

export function hasAgreedToServiceCondition(t: TFunction<["checkout" | "checkout-common"]>) {
  return z.literal(true, {
    errorMap: (issue, ctx) => ({
      message:
        issue.code === z.ZodIssueCode.invalid_literal ? t("checkout:ServiceTermsConsentRequired") : ctx.defaultError,
    }),
  })
}
