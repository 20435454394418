import { useQuery } from "@tanstack/react-query"
import { api } from "api"
import type { Store } from "features/checkout/types"

export const QUERY_KEY_STORE = "store"

type StoreQueryOptions = {
  enabled?: boolean
  pickup?: boolean
}

export function useStoreQuery(query: string, { pickup = true }: StoreQueryOptions = {}) {
  return useQuery({
    enabled: !!query,
    queryKey: [QUERY_KEY_STORE, query],
    queryFn: async ({ signal }) => {
      if (!query) {
        throw new Error("Missing query for fetching store")
      }

      const searchParams = new URLSearchParams({ limit: "8", pickup: String(pickup) })
      const { data } = await api.get<Store[]>(`/api/store/search/${encodeURI(query)}?${searchParams}`, { signal })

      return data
    },
    refetchOnWindowFocus: false,
    staleTime: Infinity,
  })
}
