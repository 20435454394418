export const klarnaErrorCodes = {
  "-1": "Er is een probleem ontstaan in de communicatie met Klarna, dat ons factureringssysteem beheert. Neem contact op met Klarna (https://www.klarna.nl) voor meer informatie, of kies een andere betaalmethode.",
  "-2": "Er is een probleem ontstaan in de communicatie met Klarna, dat ons factureringssysteem beheert. Neem contact op met Klarna (https://www.klarna.nl) voor meer informatie, of kies een andere betaalmethode.",
  "-3": "Er is een probleem ontstaan in de communicatie met Klarna, dat ons factureringssysteem beheert. Neem contact op met Klarna (https://www.klarna.nl) voor meer informatie, of kies een andere betaalmethode.",
  "-4": "Er is een probleem ontstaan in de communicatie met Klarna, dat ons factureringssysteem beheert. Neem contact op met Klarna (https://www.klarna.nl) voor meer informatie, of kies een andere betaalmethode.",
  "-5": "Er is een probleem ontstaan in de communicatie met Klarna, dat ons factureringssysteem beheert. Neem contact op met Klarna (https://www.klarna.nl) voor meer informatie, of kies een andere betaalmethode.",
  "-99":
    "Er is een probleem ontstaan in de communicatie met Klarna, dat ons factureringssysteem beheert. Neem contact op met Klarna (https://www.klarna.nl) voor meer informatie, of kies een andere betaalmethode.",
  1103: "Helaas kan Klarna je aankoop niet accepteren omdat je kredietlimiet is overschreden. Je kunt hierover contact opnemen met Klarna's klantenservice (https://www.klarna.nl) of een andere betaalmethode kiezen.",
  1104: "Er is een probleem ontstaan in de communicatie met Klarna, dat ons factureringssysteem beheert. Neem contact op met Klarna (https://www.klarna.nl) voor meer informatie, of kies een andere betaalmethode.",
  1999: "Er is een probleem ontstaan in de communicatie met Klarna, dat ons factureringssysteem beheert. Neem contact op met Klarna (https://www.klarna.nl) voor meer informatie, of kies een andere betaalmethode.",
  2101: "Er is een probleem ontstaan in de communicatie met Klarna, dat ons factureringssysteem beheert. Neem contact op met Klarna (https://www.klarna.nl) voor meer informatie, of kies een andere betaalmethode.",
  2102: "Helaas kunnen wij op dit moment je aankoop met Klarna niet goedkeuren. Kies een andere betaalmethode om je bestelling af te ronden. Onze excuses voor het ongemak.",
  2103: "Helaas kunnen wij op dit moment je aankoop met Klarna niet goedkeuren. Kies een andere betaalmethode om je bestelling af te ronden. Onze excuses voor het ongemak.",
  2104: "Helaas kan Klarna jouw aankoop niet accepteren na uitkomst van de kredietbeoordeling. Je kunt hierover contact opnemen met Klarna's klantenservice (https://www.klarna.nl) of een andere betaalmethode kiezen.",
  2105: "Helaas kunnen wij je aankoop met Klarna niet goedkeuren omdat je nog onbetaalde facturen hebt. Betaal deze facturen zodat je verder kunt winkelen met Klarna. Ga naar www.klarna.nl/mijn-klarna voor meer informatie. Wil je de bestelling nu afronden, kies dan een andere betaalmethode.",
  2106: "Helaas kan Klarna je aankoop niet accepteren na uitkomst van de kredietbeoordeling. Je kunt hierover contact opnemen met Klarna's klantenservice (https://www.klarna.nl) of kies een andere betaalmethode.",
  2107: "Er is een probleem ontstaan in de communicatie met Klarna, dat ons factureringssysteem beheert. Neem contact op met Klarna (https://www.klarna.nl) voor meer informatie, of kies een andere betaalmethode.",
  2108: "Helaas kan Klarna jouw aankoop niet accepteren na uitkomst van de kredietbeoordeling. Je kunt hierover contact opnemen met Klarna's klantenservice (https://www.klarna.nl) of kies een andere betaalmethode.",
  2109: "De volmacht voor deze persoon is niet geregistreerd bij Klarna.",
  2110: "Er is een probleem ontstaan in de communicatie met Klarna, dat ons factureringssysteem beheert. Neem contact op met Klarna (https://www.klarna.nl) voor meer informatie, of kies een andere betaalmethode.",
  2201: "Er is een probleem met de door jou opgegeven geboortedatum. Controleer of de cijfers juist zijn ingevoerd en op deze manier: DDMMJJJJ. Als je dan nog steeds deze foutmelding krijgt, verzoeken wij je een andere betaalmogelijkheid te kiezen om je bestelling af te ronden.",
  2202: "Helaas hebben wij jouw persoonsgegevens niet kunnen verifiëren. Controleer of je per ongeluk een typfout hebt gemaakt.",
  2203: "Er is een probleem met de opgegeven geboortedatum. Controleer of de cijfers juist zijn ingevoerd en op deze manier: DDMMJJJJ. Als je nog steeds deze foutmelding krijgt, verzoeken wij je een andere betaalmogelijkheid te kiezen om je bestelling af te ronden.",
  2204: "Helaas hebben wij jouw persoonsgegevens niet kunnen verifiëren. Controleer of je per ongeluk een typfout hebt gemaakt.",
  2205: "Je dient ten minste 18 jaar te zijn om van deze betaalmogelijkheid gebruik te maken. Kies een andere betaalmethode om je bestelling af te ronden.",
  2206: "Voor aankopen met Klarna dien je tenminste 18 jaar te zijn.",
  2301: "Helaas hebben wij jouw persoonsgegevens niet kunnen verifiëren. Controleer of je per ongeluk een typfout hebt gemaakt.",
  2302: "Er is een probleem met de opgegeven geboortedatum. Controleer of de cijfers juist zijn ingevoerd en op deze manier: DDMMJJJJ. Als je nog steeds deze foutmelding krijgt, verzoeken wij je een andere betaalmogelijkheid te kiezen om je bestelling af te ronden.",
  2303: "Helaas hebben wij jouw persoonsgegevens niet kunnen verifiëren. Controleer of je per ongeluk een typfout hebt gemaakt.",
  2304: "De aankoop is niet uitgevoerd. We kunnen de opgegeven persoonsgegevens niet vinden. Controleer of de opgegeven gegevens juist zijn. De facturering en termijnbetalingen voor ons bedrijf worden uitgevoerd door Klarna. Neem contact op met de klantenservice van Klarna als je de juiste gegevens hebt opgegeven en toch dit bericht ontvangt. Klik op de koppeling hieronder om naar de klantenservice van Klarna te gaan:https://www.klarna.com/nl/klantenservice/",
  2305: "Het opgegeven password is onjuist.",
  2999: "Er is een probleem ontstaan in de communicatie met Klarna, dat ons factureringssysteem beheert. Neem contact op met Klarna (https://www.klarna.nl) voor meer informatie, of kies een andere betaalmethode.",
  2401: "Als je Klarna Mobil voor de eerste keer gebruikt, moet je je aanmelden. Je hoeft alleen maar te bellen met 08-120 129 00 of naar klarna.nl te gaan. Mvg Klarna",
  2402: "Helaas was de pincode niet juist",
  2403: "Helaas was de pincode niet juist",
  2404: "Er is geen aangevraagde pincode",
  2405: "De eerste keer dat je je zakelijke mobiele telefoon gebruikt om te betalen via Klarna Mobil, moet je jouw burgerservicenummer opgeven door REG JJMMDDXXXX te verzenden naar 71200. Mvg Klarna",
  2406: "We hebben geen geregistreerd adres in het bevolkingsregister kunnen vinden voor het opgegeven telefoonnummer.",
  2501: "Het burgerservicenummer voor de gevolmachtigde heeft niet de juiste notatie. Geef het burgerservicenummer op als JJJJMMDDNNNN.",
  2502: "Er is een probleem ontstaan in de communicatie met Klarna, dat ons factureringssysteem beheert. Neem contact op met Klarna (https://www.klarna.nl) voor meer informatie, of kies een andere betaalmethode.",
  2503: "Organisatienummer heeft niet de juiste indeling.",
  3101: "Het door jou opgegeven adres komt niet overeen met onze gegevens. Controleer of je per ongeluk een typfout hebt gemaakt of kies een andere betaalmethode.",
  3102: "Helaas is er een probleem met het door jou opgegeven adres. Controleer het adres op volledigheid en spelfouten en probeer het nogmaals. Als je dan nog steeds deze foutmelding krijgt, neem dan contact met Klarna op via www.klarna.nl/klantenservice.",
  3103: "Er is een probleem ontstaan in de communicatie met Klarna, dat ons factureringssysteem beheert. Neem contact op met Klarna (https://www.klarna.nl) voor meer informatie, of kies een andere betaalmethode.",
  3104: "Je hebt een onjuiste postcode opgegeven. Controleer jouw gegevens of kies een andere betaalmethode.",
  3105: "Je hebt een onjuiste postcode opgegeven. Controleer jouw gegevens of kies een andere betaalmethode.",
  3106: "Er is een probleem met de door jou opgegeven naam. Controleer jouw naam op spelfouten, voer de volledige naam in en probeer het nogmaals. Als je dan nog steeds deze foutmelding krijgt, neem dan contact met Klarna op via www.klarna.nl/klantenservice.",
  3107: "Er is een probleem met het door jou opgegeven adres. Controleer het adres op spelfouten, voer het volledige adres in en probeer het nogmaals. Als je dan nog steeds deze foutmelding krijgt, neem dan contact met Klarna op via www.klarna.nl/klantenservice.",
  3108: "Er is een probleem met het door jou opgegeven adres. Controleer het adres op spelfouten, voer het volledige adres in en probeer het nogmaals. Als je dan nog steeds deze foutmelding krijgt, kies dan een andere betaalmethode.",
  3109: "Jouw aankoop kan helaas niet worden goedgekeurd. De aankooplimiet voor een niet-geregistreerd adres is overschreden. Als je toch de aankoop wil voortzetten, geef dan het geregistreerde adres op als afleveradres. Of kies een andere betaalmethode.",
  3110: "Helaas is er een probleem met het door jou opgegeven adres. Controleer het adres op spelfouten, voer het volledige adres in en probeer het nogmaals. Als je dan nog steeds deze foutmelding krijgt, neem dan contact met Klarna op via www.klarna.nl/klantenservice.",
  3111: "Er is een probleem met de door jou opgegeven postcode. Voer het nogmaals in volgens dit voorbeeld: NNNN AA. Als je dan nog steeds deze foutmelding krijgt, verzoeken wij je een andere betaalmethode te kiezen om je bestelling af te ronden.",
  3201: "Er is een probleem met het door jou opgegeven telefoonnummer. Controleer of alle cijfers juist zijn ingevoerd volgens dit voorbeeld: 06-XX XX XX XX. Als je dan nog steeds deze foutmelding krijgt, verzoeken wij je een andere betaalmethode te kiezen om je bestelling af te ronden.",
  3202: "Het door jou opgegeven telefoonnummer is onjuist. Controleer of alle cijfers juist zijn ingevoerd, en dat het formaat als volgt is: 06-XX XX XX XX. Als je dan nog steeds deze foutmelding krijgt, verzoeken wij je een andere betaalmethode te kiezen om jouw bestelling af te ronden.",
  3203: "Helaas hebben wij het opgegeven e-mailadres niet kunnen verifiëren. Controleer of er een typefout is gemaakt.",
  3204: "Er is een probleem ontstaan in de communicatie met Klarna, dat ons factureringssysteem beheert. Neem contact op met Klarna (https://www.klarna.nl) voor meer informatie, of kies een andere betaalmethode.",
  3205: "De aankoop is niet uitgevoerd. De opgegeven plaatsnaam komt niet overeen met het adres in het bevolkingsregister. Controleer of de plaats correct is opgegeven. De facturering en termijnbetalingen voor ons bedrijf worden uitgevoerd door Klarna. Neem contact op met de klantenservice van Klarna als je het juiste adres hebt opgegeven en toch dit bericht ontvangt. Klik op de koppeling hieronder om naar de klantenservice van Klarna te gaan: https://www.klarna.com/nl/klantenservice/",
  3206: "Je hebt een onjuiste postcode opgegeven. Controleer of je een typfout hebt gemaakt of kies een andere betaalmethode.",
  3207: "De aankoop is niet uitgevoerd. De opgegeven straatnaam komt niet overeen met het adres in het bevolkingsregister. Controleer of het adres correct is opgegeven. De facturering en termijnbetalingen voor ons bedrijf worden uitgevoerd door Klarna. Neem contact op met de klantenservice van Klarna als je het juiste adres hebt opgegeven en toch dit bericht ontvangt. Klik op de koppeling hieronder om naar de klantenservice van Klarna te gaan:https://www.klarna.com/nl/klantenservice/",
  3208: "Er is een probleem ontstaan in de communicatie met Klarna, dat ons factureringssysteem beheert. Neem contact op met Klarna (https://www.klarna.nl) voor meer informatie, of kies een andere betaalmethode.",
  3209: "Er is een probleem ontstaan in de communicatie met Klarna, dat ons factureringssysteem beheert. Neem contact op met Klarna (https://www.klarna.nl) voor meer informatie, of kies een andere betaalmethode.",
  3210: "Er zit een fout in de artikellijst die je naar Klarna hebt gestuurd. Controleer of alles juist en volledig is verzonden.",
  3211: "We kunnen de artikellijst in deze order niet herkennen. Controleer of de artikelnummers overeenkomen met de order, en probeer het nog een keer.",
  3212: "Er is een probleem ontstaan in de communicatie met Klarna, dat ons factureringssysteem beheert. Neem contact op met Klarna (https://www.klarna.nl) voor meer informatie, of kies een andere betaalmethode.",
  3213: "The language you entered is not supported by Klarna.",
  3214: "Selecteer jouw geslacht en probeer het nogmaals.",
  3215: "Er is een probleem met de door jou opgegeven geboortedatum. Controleer of de cijfers juist zijn ingevoerd en op deze manier: DDMMJJJJ. Als je dan nog steeds deze foutmelding krijgt, verzoeken wij je een andere betaalmethode te kiezen om je bestelling af te ronden.",
  3216: "Er is een probleem ontstaan in de communicatie met Klarna, dat ons factureringssysteem beheert. Neem contact op met Klarna (https://www.klarna.nl) voor meer informatie, of kies een andere betaalmethode.",
  3217: "De aankoop is niet uitgevoerd. Het opgegeven adres (de straat of de gemeente) komt niet overeen met het adres in het bevolkingsregister. Controleer of het adres correct is opgegeven. De facturering en termijnbetalingen voor ons bedrijf worden uitgevoerd door Klarna. Neem contact op met de klantenservice van Klarna als je het juiste adres hebt opgegeven en toch dit bericht ontvangt. Klik op de koppeling hieronder om naar de klantenservice van Klarna te gaan: https://www.klarna.com/nl/klantenservice/",
  3218: "Je hebt geen of een ongeldig huisnummer opgegeven. Controleer nogmaals je gegevens of kies een andere betaalmethode.",
  3219: "Er is een probleem met het door jou opgegeven telefoonnummer. Controleer of alle cijfers juist zijn ingevoerd volgens dit voorbeeld: 06-XX XX XX XX. Als je dan nog steeds deze foutmelding krijgt, verzoeken wij je een andere betaalmethode te kiezen om je bestelling af te ronden.",
  3220: "Er is een probleem ontstaan in de communicatie met Klarna, dat ons factureringssysteem beheert. Neem contact op met Klarna (https://www.klarna.nl) voor meer informatie, of kies een andere betaalmethode.",
  3221: "Helaas is er een probleem opgetreden bij het verwerken van jouw aankoop. Controleer alle ingevoerde gegevens en probeer het nogmaals. Als je dan nog steeds deze foutmelding krijgt, verzoeken wij je een andere betaalmethode te kiezen om je bestelling af te ronden. Onze excuses voor het ongemak.",
  3301: "De aankoop is niet uitgevoerd. De opgegeven naam en het opgegeven adres komen niet overeen met het adres in het bevolkingsregister. Controleer of de naam en het adres correct zijn opgegeven. De facturering en termijnbetalingen voor ons bedrijf worden uitgevoerd door Klarna. Neem contact op met de klantenservice van Klarna als je de juiste naam en het juiste adres hebt opgegeven en toch dit bericht ontvangt. Klik op de koppeling hieronder om naar de klantenservice van Klarna te gaan:https://www.klarna.com/nl/klantenservice/",
  3302: "Vul alstublieft je achternaam in.",
  3303: "Vul alstublieft je voornaam in.",
  3304: "De aankoop is niet uitgevoerd. De opgegeven naam komt niet overeen met het adres in het bevolkingsregister. Controleer of de voor- en achternaam correct zijn opgegeven. De facturering en termijnbetalingen voor ons bedrijf worden uitgevoerd door Klarna. Neem contact op met de klantenservice van Klarna als je de juiste naam hebt opgegeven en toch dit bericht ontvangt. Klik op de koppeling hieronder om naar de klantenservice van Klarna te gaan: https://www.klarna.com/nl/klantenservice/",
  3305: "Er is een probleem ontstaan in de communicatie met Klarna, dat ons factureringssysteem beheert. Neem contact op met Klarna (https://www.klarna.nl) voor meer informatie, of kies een andere betalingswijze.",
  3999: "Er is een probleem ontstaan in de communicatie met Klarna, dat ons factureringssysteem beheert. Neem contact op met Klarna (https://www.klarna.nl) voor meer informatie, of kies een andere betalingswijze.",
  6101: "Er is een probleem ontstaan in de communicatie met Klarna, dat ons factureringssysteem beheert. Neem contact op met Klarna (https://www.klarna.nl) voor meer informatie, of kies een andere betalingswijze.",
  6102: "Er is een probleem ontstaan in de communicatie met Klarna, dat ons factureringssysteem beheert. Neem contact op met Klarna (https://www.klarna.nl) voor meer informatie, of kies een andere betalingswijze.",
  6103: "Er is een probleem ontstaan in de communicatie met Klarna, dat ons factureringssysteem beheert. Neem contact op met Klarna (https://www.klarna.nl) voor meer informatie, of kies een andere betalingswijze.",
  6104: "Er is een probleem ontstaan in de communicatie met Klarna, dat ons factureringssysteem beheert. Neem contact op met Klarna (https://www.klarna.nl) voor meer informatie, of kies een andere betalingswijze.",
  6105: "De campagne die je probeert te gebruiken, is niet meer geldig. Gelieve een andere campagne te gebruiken.",
  6106: "Er is een probleem ontstaan in de communicatie met Klarna, dat ons factureringssysteem beheert. Neem contact op met Klarna (https://www.klarna.nl) voor meer informatie, of kies een andere betalingswijze.",
  6999: "Er is een probleem ontstaan in de communicatie met Klarna, dat ons factureringssysteem beheert. Neem contact op met Klarna (https://www.klarna.nl) voor meer informatie, of kies een andere betalingswijze.",
  7999: "Er is een probleem ontstaan in de communicatie met Klarna, dat ons factureringssysteem beheert. Neem contact op met Klarna (https://www.klarna.nl) voor meer informatie, of kies een andere betalingswijze.",
  8101: "Er is een probleem ontstaan in de communicatie met Klarna, dat ons factureringssysteem beheert. Neem contact op met Klarna (https://www.klarna.nl) voor meer informatie, of kies een andere betalingswijze.",
  8102: "Er is een probleem ontstaan in de communicatie met Klarna, dat ons factureringssysteem beheert. Neem contact op met Klarna (https://www.klarna.nl) voor meer informatie, of kies een andere betalingswijze.",
  8103: "Er is een probleem ontstaan in de communicatie met Klarna, dat ons factureringssysteem beheert. Neem contact op met Klarna (https://www.klarna.nl) voor meer informatie, of kies een andere betalingswijze.",
  8104: "Er is een probleem ontstaan in de communicatie met Klarna, dat ons factureringssysteem beheert. Neem contact op met Klarna (https://www.klarna.nl) voor meer informatie, of kies een andere betalingswijze.",
  8105: "Er is een probleem ontstaan in de communicatie met Klarna, dat ons factureringssysteem beheert. Neem contact op met Klarna (https://www.klarna.nl) voor meer informatie, of kies een andere betalingswijze.",
  8106: "Er is een probleem ontstaan in de communicatie met Klarna, dat ons factureringssysteem beheert. Neem contact op met Klarna (https://www.klarna.nl) voor meer informatie, of kies een andere betalingswijze.",
  8107: "Er is een probleem ontstaan in de communicatie met Klarna, dat ons factureringssysteem beheert. Neem contact op met Klarna (https://www.klarna.nl) voor meer informatie, of kies een andere betalingswijze.",
  8108: "Er is een probleem ontstaan in de communicatie met Klarna, dat ons factureringssysteem beheert. Neem contact op met Klarna (https://www.klarna.nl) voor meer informatie, of kies een andere betalingswijze.",
  8109: "Er is een probleem ontstaan in de communicatie met Klarna, dat ons factureringssysteem beheert. Neem contact op met Klarna (https://www.klarna.nl) voor meer informatie, of kies een andere betalingswijze.",
  8110: "Er is een probleem ontstaan in de communicatie met Klarna, dat ons factureringssysteem beheert. Neem contact op met Klarna (https://www.klarna.nl) voor meer informatie, of kies een andere betalingswijze.",
  8111: "Er is een probleem ontstaan in de communicatie met Klarna, dat ons factureringssysteem beheert. Neem contact op met Klarna (https://www.klarna.nl) voor meer informatie, of kies een andere betalingswijze.",
  8112: "Er is een probleem ontstaan in de communicatie met Klarna, dat ons factureringssysteem beheert. Neem contact op met Klarna (https://www.klarna.nl) voor meer informatie, of kies een andere betalingswijze.",
  8113: "Er is een probleem ontstaan in de communicatie met Klarna, dat ons factureringssysteem beheert. Neem contact op met Klarna (https://www.klarna.nl) voor meer informatie, of kies een andere betalingswijze.",
  8114: "Er is een probleem ontstaan in de communicatie met Klarna, dat ons factureringssysteem beheert. Neem contact op met Klarna (https://www.klarna.nl) voor meer informatie, of kies een andere betalingswijze.",
  8999: "Er is een probleem ontstaan in de communicatie met Klarna, dat ons factureringssysteem beheert. Neem contact op met Klarna (https://www.klarna.nl) voor meer informatie, of kies een andere betalingswijze.",
  9101: "Er is een probleem ontstaan in de communicatie met Klarna, dat ons factureringssysteem beheert. Neem contact op met Klarna (https://www.klarna.nl) voor meer informatie, of kies een andere betalingswijze.",
  9102: "Er is een probleem ontstaan in de communicatie met Klarna, dat ons factureringssysteem beheert. Neem contact op met Klarna (https://www.klarna.nl) voor meer informatie, of kies een andere betalingswijze.",
  9103: "Er is een probleem ontstaan in de communicatie met Klarna, dat ons factureringssysteem beheert. Neem contact op met Klarna (https://www.klarna.nl) voor meer informatie, of kies een andere betalingswijze.",
  9104: "Er is een probleem ontstaan in de communicatie met Klarna, dat ons factureringssysteem beheert. Neem contact op met Klarna (https://www.klarna.nl) voor meer informatie, of kies een andere betalingswijze.",
  9105: "Er is een probleem ontstaan in de communicatie met Klarna, dat ons factureringssysteem beheert. Neem contact op met Klarna (https://www.klarna.nl) voor meer informatie, of kies een andere betalingswijze.",
  9106: "Er is een probleem ontstaan in de communicatie met Klarna, dat ons factureringssysteem beheert. Neem contact op met Klarna (https://www.klarna.nl) voor meer informatie, of kies een andere betalingswijze.",
  9107: "We kunnen de artikellijst in deze order niet herkennen. Controleer of de artikelnummers overeenkomen met de order, en probeer het nog een keer.",
  9108: "Er is een probleem ontstaan in de communicatie met Klarna, dat ons factureringssysteem beheert. Neem contact op met Klarna (https://www.klarna.nl) voor meer informatie, of kies een andere betalingswijze.",
  9109: "We kunnen de reservering die je probeert te gebruiken niet vinden. Controleer of je het juiste reserveringsnummer gebruikt, en probeer het nog een keer.",
  9110: "Er is een probleem ontstaan in de communicatie met Klarna, dat ons factureringssysteem beheert. Neem contact op met Klarna (https://www.klarna.nl) voor meer informatie, of kies een andere betalingswijze.",
  9111: "Er is een probleem ontstaan in de communicatie met Klarna, dat ons factureringssysteem beheert. Neem contact op met Klarna (https://www.klarna.nl) voor meer informatie, of kies een andere betalingswijze.",
  9112: "The OCR number sent is not valid.",
  9113: "Er is een integratiefout ontstaan tussen de webwinkel en Klarna. Neem contact op met de webwinkel voor meer info of kies een andere betaalmethode. # msgid",
  9114: "Er is een integratiefout ontstaan tussen de webwinkel en Klarna. Neem contact op met de webwinkel voor meer info of kies een andere betaalmethode. # msgid",
  9115: "Er is een probleem ontstaan in de communicatie met Klarna, dat ons factureringssysteem beheert. Neem contact op met Klarna (https://www.klarna.nl) voor meer informatie, of kies een andere betalingswijze.",
  9116: "Het persoonnummertype wordt niet goedgekeurd.",
  9117: "Er is een probleem ontstaan in de communicatie met Klarna, dat ons factureringssysteem beheert. Neem contact op met Klarna (https://www.klarna.nl) voor meer informatie, of kies een andere betalingswijze.",
  9118: "Er is een probleem ontstaan in de communicatie met Klarna, dat ons factureringssysteem beheert. Neem contact op met Klarna (https://www.klarna.nl) voor meer informatie, of kies een andere betalingswijze.",
  9119: "Helaas kunnen wij je aankoop met Klarna op dit moment niet goedkeuren vanwege technische problemen. Probeer het over een paar minuten nogmaals. Als je dan nog steeds deze foutmelding krijgt, verzoeken wij je een andere betaalmethode te kiezen om je bestelling af te ronden. Onze excuses voor het ongemak.",
  9120: "Er is een probleem ontstaan in de communicatie met Klarna, dat ons factureringssysteem beheert. Neem contact op met Klarna (https://www.klarna.nl) voor meer informatie, of kies een andere betalingswijze.",
  9121: "Er is een probleem ontstaan in de communicatie met Klarna, dat ons factureringssysteem beheert. Neem contact op met Klarna (https://www.klarna.nl) voor meer informatie, of kies een andere betalingswijze.",
  9124: "Er is een probleem ontstaan in de communicatie met Klarna, dat ons factureringssysteem beheert. Neem contact op met Klarna (https://www.klarna.nl) voor meer informatie, of kies een andere betalingswijze.",
  9125: "Er is een integratiefout onstaan tussen de webwinkel en Klarna. Gelieve contact opnemen met de webwinkel voor meer info of kies een andere betalingswijze. # msgid",
  9126: "Er is geen gedefinieerd gedrag voor de opgegeven estore_gedrag waarde.",
  9127: "Er is een probleem ontstaan in de communicatie met Klarna, dat ons factureringssysteem beheert. Neem contact op met Klarna (https://www.klarna.nl) voor meer informatie, of kies een andere betalingswijze.",
  9128: "Er is een probleem ontstaan in de communicatie met Klarna, dat ons factureringssysteem beheert. Neem contact op met Klarna (https://www.klarna.nl) voor meer informatie, of kies een andere betalingswijze.",
  9129: "Er is een probleem ontstaan in de communicatie met Klarna, dat ons factureringssysteem beheert. Neem contact op met Klarna (https://www.klarna.nl) voor meer informatie, of kies een andere betalingswijze.",
  9130: "Deze bestelling wordt nu handmatig gecontroleerd, gebruik checkorderstatus om de huidige bestelstatus te bekijken.",
  9131: "We kunnen de order waarop u deze actie probeert uit te voeren niet vinden. Controleer of u de juiste order hebt geselecteerd en probeer het nog een keer.",
  9191: "Helaas kunnen wij uw aankoop met Klarna op dit moment niet goedkeuren vanwege technische problemen. Probeer het over een paar minuten nogmaals. Als u dan nog steeds deze foutmelding krijgt, verzoeken wij u een andere betaalmogelijkheid te kiezen om uw bestelling af te ronden. Onze excuses voor het ongemak.",
  9201: "Er is een probleem ontstaan in de communicatie met Klarna, dat ons factureringssysteem beheert. Neem contact op met Klarna (https://www.klarna.nl) voor meer informatie, of kies een andere betalingswijze.",
  9202: "Er is een probleem ontstaan in de communicatie met Klarna, dat ons factureringssysteem beheert. Neem contact op met Klarna (https://www.klarna.nl) voor meer informatie, of kies een andere betalingswijze.",
  9203: "Er is een probleem ontstaan in de communicatie met Klarna, dat ons factureringssysteem beheert. Neem contact op met Klarna (https://www.klarna.nl) voor meer informatie, of kies een andere betalingswijze.",
  9204: "Er is een probleem ontstaan in de communicatie met Klarna, dat ons factureringssysteem beheert. Neem contact op met Klarna (https://www.klarna.nl) voor meer informatie, of kies een andere betalingswijze.",
  9205: "Er is een probleem ontstaan in de communicatie met Klarna, dat ons factureringssysteem beheert. Neem contact op met Klarna (https://www.klarna.nl) voor meer informatie, of kies een andere betalingswijze.",
  9206: "Er is een probleem ontstaan in de communicatie met Klarna, dat ons factureringssysteem beheert. Neem contact op met Klarna (https://www.klarna.nl) voor meer informatie, of kies een andere betalingswijze.",
  9207: "Er is een probleem ontstaan in de communicatie met Klarna, dat ons factureringssysteem beheert. Neem contact op met Klarna (https://www.klarna.nl) voor meer informatie, of kies een andere betalingswijze.",
  9208: "Er is een probleem ontstaan in de communicatie met Klarna, dat ons factureringssysteem beheert. Neem contact op met Klarna (https://www.klarna.nl) voor meer informatie, of kies een andere betalingswijze.",
  9209: "Er is een probleem ontstaan in de communicatie met Klarna, dat ons factureringssysteem beheert. Neem contact op met Klarna (https://www.klarna.nl) voor meer informatie, of kies een andere betalingswijze.",
  9210: "Er is een probleem ontstaan in de communicatie met Klarna, dat ons factureringssysteem beheert. Neem contact op met Klarna (https://www.klarna.nl) voor meer informatie, of kies een andere betalingswijze.",
  9211: "Er is een probleem ontstaan in de communicatie met Klarna, dat ons factureringssysteem beheert. Neem contact op met Klarna (https://www.klarna.nl) voor meer informatie, of kies een andere betalingswijze.",
  9212: "Er is een probleem ontstaan in de communicatie met Klarna, dat ons factureringssysteem beheert. Neem contact op met Klarna (https://www.klarna.nl) voor meer informatie, of kies een andere betalingswijze.",
  9213: "Er is een probleem ontstaan in de communicatie met Klarna, dat ons factureringssysteem beheert. Neem contact op met Klarna (https://www.klarna.nl) voor meer informatie, of kies een andere betalingswijze.",
  9215: "Er is een probleem ontstaan in de communicatie met Klarna, dat ons factureringssysteem beheert. Neem contact op met Klarna (https://www.klarna.nl) voor meer informatie, of kies een andere betalingswijze.",
  9216: "Er is een probleem ontstaan in de communicatie met Klarna, dat ons factureringssysteem beheert. Neem contact op met Klarna (https://www.klarna.nl) voor meer informatie, of kies een andere betalingswijze.",
  9217: "Er is een probleem ontstaan in de communicatie met Klarna, dat ons factureringssysteem beheert. Neem contact op met Klarna (https://www.klarna.nl) voor meer informatie, of kies een andere betalingswijze.",
  9219: "Er is een probleem ontstaan in de communicatie met Klarna, dat ons factureringssysteem beheert. Neem contact op met Klarna (https://www.klarna.nl) voor meer informatie, of kies een andere betalingswijze.",
  9220: "Er is een probleem ontstaan in de communicatie met Klarna, dat ons factureringssysteem beheert. Neem contact op met Klarna (https://www.klarna.nl) voor meer informatie, of kies een andere betalingswijze.",
  9221: "Er is een probleem ontstaan in de communicatie met Klarna, dat ons factureringssysteem beheert. Neem contact op met Klarna (https://www.klarna.nl) voor meer informatie, of kies een andere betalingswijze.",
  9222: "Er is een probleem ontstaan in de communicatie met Klarna, dat ons factureringssysteem beheert. Neem contact op met Klarna (https://www.klarna.nl) voor meer informatie, of kies een andere betalingswijze.",
  9223: "Er is een probleem ontstaan in de communicatie met Klarna, dat ons factureringssysteem beheert. Neem contact op met Klarna (https://www.klarna.nl) voor meer informatie, of kies een andere betalingswijze.",
  9224: "Er is een probleem ontstaan in de communicatie met Klarna, dat ons factureringssysteem beheert. Neem contact op met Klarna (https://www.klarna.nl) voor meer informatie, of kies een andere betalingswijze.",
  9225: "Er is een probleem ontstaan in de communicatie met Klarna, dat ons factureringssysteem beheert. Neem contact op met Klarna (https://www.klarna.nl) voor meer informatie, of kies een andere betalingswijze.",
  9226: "Er is een probleem ontstaan in de communicatie met Klarna, dat ons factureringssysteem beheert. Neem contact op met Klarna (https://www.klarna.nl) voor meer informatie, of kies een andere betalingswijze.",
  9227: "Er is een probleem ontstaan in de communicatie met Klarna, dat ons factureringssysteem beheert. Neem contact op met Klarna (https://www.klarna.nl) voor meer informatie, of kies een andere betalingswijze.",
  9228: "Er is een probleem ontstaan in de communicatie met Klarna, dat ons factureringssysteem beheert. Neem contact op met Klarna (https://www.klarna.nl) voor meer informatie, of kies een andere betalingswijze.",
  9229: "Er is een probleem ontstaan in de communicatie met Klarna, dat ons factureringssysteem beheert. Neem contact op met Klarna (https://www.klarna.nl) voor meer informatie, of kies een andere betalingswijze.",
  9230: "Er is een probleem ontstaan in de communicatie met Klarna, dat ons factureringssysteem beheert. Neem contact op met Klarna (https://www.klarna.nl) voor meer informatie, of kies een andere betalingswijze.",
  9231: "Er is een probleem ontstaan in de communicatie met Klarna, dat ons factureringssysteem beheert. Neem contact op met Klarna (https://www.klarna.nl) voor meer informatie, of kies een andere betalingswijze.",
  9232: "Er is een probleem ontstaan in de communicatie met Klarna, dat ons factureringssysteem beheert. Neem contact op met Klarna (https://www.klarna.nl) voor meer informatie, of kies een andere betalingswijze.",
  9233: "Er is een probleem ontstaan in de communicatie met Klarna, dat ons factureringssysteem beheert. Neem contact op met Klarna (https://www.klarna.nl) voor meer informatie, of kies een andere betalingswijze.",
  9234: "Er is een probleem ontstaan in de communicatie met Klarna, dat ons factureringssysteem beheert. Neem contact op met Klarna (https://www.klarna.nl) voor meer informatie, of kies een andere betalingswijze.",
  9235: "Er is een probleem ontstaan in de communicatie met Klarna, dat ons factureringssysteem beheert. Neem contact op met Klarna (https://www.klarna.nl) voor meer informatie, of kies een andere betalingswijze.",
  9236: "Er is een probleem ontstaan in de communicatie met Klarna, dat ons factureringssysteem beheert. Neem contact op met Klarna (https://www.klarna.nl) voor meer informatie, of kies een andere betalingswijze.",
  9237: "Er is een probleem ontstaan in de communicatie met Klarna, dat ons factureringssysteem beheert. Neem contact op met Klarna (https://www.klarna.nl) voor meer informatie, of kies een andere betalingswijze.",
  9238: "Er is een probleem ontstaan in de communicatie met Klarna, dat ons factureringssysteem beheert. Neem contact op met Klarna (https://www.klarna.nl) voor meer informatie, of kies een andere betalingswijze.",
  9239: "Er is een probleem ontstaan in de communicatie met Klarna, dat ons factureringssysteem beheert. Neem contact op met Klarna (https://www.klarna.nl) voor meer informatie, of kies een andere betalingswijze.",
  9240: "Er heeft zich een fout voorgedaan doordat de protocol versie niet overeenkomt met wat ingesteld staat in je instellingen. Neem contact op met Klarna om ondersteuning te krijgen met het upgraden naar de huidige versie.",
  9241: "Er is een probleem ontstaan in de communicatie met Klarna, dat ons factureringssysteem beheert. Neem contact op met Klarna (https://www.klarna.nl) voor meer informatie, of kies een andere betalingswijze.",
  2306: "Het is niet mogelijk testklanten te gebruiken als de webwinkel in productie staat. Als je de integratie met Klarna wil testen, zet dan de testvlag in de API-aanroep.",
  2307: "Om bestellingen via Klarna met echte klanten te kunnen doen of te activeren, moet je de testvlag uit de API-aanroep verwijderen.",
  3112: "Dit adres kan niet gebruikt worden. Neem gerust contact op met Klarna voor meer informatie.",
  2308: "Als de webwinkel in testmodus is, kunnen er geen bestellingen geplaatst worden. Neem contact op met de webwinkel en/of kies een andere betaalmethode.",
}

export const knownEmailDomains = [
  "12move.nl",
  "a2000.nl",
  "alice.nl",
  "aol.com",
  "belgacom.net",
  "caiway.nl",
  "casema.nl",
  "chello.nl",
  "facebook.com",
  "freeler.nl",
  "gmail.com",
  "gmx.com",
  "google.com",
  "googlemail.com",
  "hccnet.nl",
  "hetnet.nl",
  "home.nl",
  "hotmail.be",
  "hotmail.co.uk",
  "hotmail.com",
  "hotmail.fr",
  "hotmail.nl",
  "icloud.com",
  "ilse.nl",
  "kabelfoon.nl",
  "kpnmail.nl",
  "kpnplanet.nl",
  "live.be",
  "live.com",
  "live.fr",
  "live.nl",
  "lycos.nl",
  "mail.be",
  "me.com",
  "msn.com",
  "online.nl",
  "onsbrabantnet.nl",
  "onsmail.nl",
  "orange.nl",
  "outlook.be",
  "outlook.com",
  "outlook.fr",
  "pandora.be",
  "planet.nl",
  "planet.nl",
  "proximus.be",
  "quicknet.nl",
  "scarlet.be",
  "scarlet.nl",
  "skynet.be",
  "tele2.nl",
  "telenet.be",
  "telfort.nl",
  "telfortglasvezel.nl",
  "tiscali.nl",
  "tvcablenet.be",
  "upcmail.nl",
  "voo.be",
  "wanadoo.nl",
  "wp.pl",
  "wxs.nl",
  "xmsnet.nl",
  "xmsweb.nl",
  "xs4all.nl",
  "yahoo.co.uk",
  "yahoo.com",
  "yahoo.fr",
  "zeelandnet.nl",
  "ziggo.nl",
  "zonnet.nl",
]
