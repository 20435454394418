import { dispatchAdobeEvent } from "@intergamma/adobe-tracking"
import { useCallback } from "react"
import type { GlobalError } from "react-hook-form"
import { useFormContext } from "react-hook-form"

const trackedFields = [
  "billingAddress",
  "hasShippingAddress",
  "shippingAddress",
  "hasAgreedToServiceCondition",
  "payment",
  "holderName",
]

export function useTrackFormFieldState() {
  const { getFieldState, trigger, getValues } = useFormContext()

  return useCallback(
    (element?: HTMLFormElement) => {
      const name = element?.name ?? ""
      const [firstPartOfFieldName] = name.split(".")

      if (!element || !trackedFields.includes(firstPartOfFieldName)) return

      const label = ["payment", "holderName"].includes(firstPartOfFieldName) ? "Payment info" : "Shipping info"

      // @todo: Use something else to determine form field state onBlur if `trigger` is causing issues
      trigger(name).then((isValid) => {
        if (!isValid) {
          const state = getFieldState(name)
          const closestParentContainer = element.closest(".paymentmethod") ?? element.closest("form")
          const inputElements = closestParentContainer?.querySelectorAll<HTMLInputElement>("input, select") ?? []
          const position = Array.from(inputElements)
            .filter((el) => el.type !== "hidden")
            .findIndex((el) => el.name === name)
          const paymentMethod = getValues("payment.type")
          const fieldName = label === "Payment info" ? `${paymentMethod}: ${name}` : name

          dispatchAdobeEvent({
            type: "checkout",
            data: {
              user_selected_element: `${label} field error`,
              user_selected_value: fieldName,
              user_message_value: `Error: ${state.error?.message}`,
              user_message_type: `Position: ${position + 1}`,
            },
          })

          return
        }

        switch (element.type) {
          case "checkbox": {
            return dispatchAdobeEvent({
              type: "checkout",
              data: {
                user_selected_element: name,
                user_selected_value: `Checked: ${element.checked}`,
              },
            })
          }
          case "select-one": {
            const allOptions = element.closest("select")?.querySelectorAll("option") ?? []
            const option = Array.from(allOptions).find((el) => el.value === element.value)
            const position = Array.from(allOptions).findIndex((el) => el.value === element.value)

            return dispatchAdobeEvent({
              type: "checkout",
              data: {
                user_selected_element: name === "payment.id" ? "Ideal bank selection" : name,
                user_selected_value: option?.innerText,
                user_message_type: `Position: ${position + 1}`,
              },
            })
          }
          default: {
            const closestParentContainer = element.closest(".paymentmethod") ?? element.closest("form")
            const inputElements = closestParentContainer?.querySelectorAll<HTMLInputElement>("input, select") ?? []
            const position = Array.from(inputElements)
              .filter((el) => el.type !== "hidden")
              .findIndex((el) => el.name === name)
            const paymentMethod = getValues("payment.type")
            const fieldName = label === "Payment info" ? `${paymentMethod}: ${name}` : name

            return dispatchAdobeEvent({
              type: "checkout",
              data: {
                user_selected_element: `${label} field success`,
                user_selected_value: fieldName,
                user_message_type: `Position: ${position + 1}`,
              },
            })
          }
        }
      })
    },
    [getFieldState, trigger, getValues]
  )
}

export function useTrackFormFieldSubmitErrors() {
  const { getValues } = useFormContext()

  return useCallback(
    (errors: Record<string, any>) => {
      const fieldErrors = flattenFieldErrors(errors)
      Object.entries(fieldErrors).forEach(([name, error]) => {
        const [firstPartOfFieldName] = name.split(".")

        if (!trackedFields.includes(firstPartOfFieldName)) return

        const label = ["payment", "holderName"].includes(firstPartOfFieldName) ? "Payment info" : "Shipping info"
        const paymentMethod = getValues("payment.type")
        const fieldName = label === "Payment info" ? `${paymentMethod}: ${name}` : name

        dispatchAdobeEvent({
          type: "checkout",
          data: {
            user_selected_element: `${label} field error after payment click`,
            user_selected_value: fieldName,
            user_message_value: `Error: ${error?.message}`,
          },
        })
      })
    },
    [getValues]
  )
}

function flattenFieldErrors(
  errors: GlobalError | Record<string, GlobalError>,
  path = "",
  output: Record<string, GlobalError> = {}
) {
  Object.entries(errors).forEach(([key, value]) => {
    const newPath = path ? `${path}.${key}` : key
    if (Object.hasOwn(value, "type") && Object.hasOwn(value, "message")) {
      output[newPath] = value
    } else {
      flattenFieldErrors(value, newPath, output)
    }
  })

  return output
}
