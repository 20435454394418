import { dispatchAdobeEvent } from "@intergamma/adobe-tracking"
import { useSessionDataQuery } from "api/myaccount/session-data"
import Cookies from "js-cookie"
import { useEffect, useState } from "react"

const TRACKING_COOKIE_NAME = "sendIsLoggedInEvent"

export function useTrackLoggedIn() {
  const sessionDataQuery = useSessionDataQuery()
  const [isLoggedInEventAlreadySent, setIsLoggedInEventAlreadySent] = useState(
    Cookies.get(TRACKING_COOKIE_NAME) === "true"
  )

  const userQueryIsSuccess = sessionDataQuery.isSuccess
  const userIsLoggedIn = sessionDataQuery.data?.isLoggedIn
  const userUid = sessionDataQuery.data?.uid

  useEffect(() => {
    // Check for falsy values (true string will not pass)
    if (!isLoggedInEventAlreadySent && userQueryIsSuccess && userIsLoggedIn && userUid) {
      dispatchAdobeEvent({
        type: "is_logged_in",
        data: {
          kdbid: userUid,
        },
      })

      setIsLoggedInEventAlreadySent(true)
      Cookies.set(TRACKING_COOKIE_NAME, String(true))
    }
  }, [isLoggedInEventAlreadySent, userQueryIsSuccess, userIsLoggedIn, userUid])
}
