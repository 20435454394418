import { globalConfig } from "config/global"
import { useSearchParams } from "react-router-dom"

export function useIsDebugEnabled() {
  const [params] = useSearchParams()

  if (params.has("form-debug")) {
    return params.get("form-debug") === "true"
  }

  return !globalConfig.isProduction
}
