import { karwei_nl as theme } from "@intergamma/theme"
import { KARWEI, NL } from "config/constants"
import { globalConfig } from "config/global"
import type { BaseFormulaConfig } from "config/types"

export type KarweiNLBaseDomain = "karwei.nl" | "acceptatie.karwei.nl"
export type KarweiNLCookiePrefix = "prd-karwei-nl" | "acc-karwei-nl"

const baseDomain = globalConfig.isAcceptance ? "acceptatie.karwei.nl" : "karwei.nl"

export const karwei_nl: BaseFormulaConfig<KarweiNLBaseDomain, KarweiNLCookiePrefix> = {
  baseDomain,
  brand: KARWEI,
  brandName: "Karwei",
  contentfulCartDeliveryInformationEntryId: "4RYcFINZV1Ve2yX79omS1s",
  contentfulConfirmationDifmInfoEntryId: "",
  contentfulContentDeliveryApiAccessToken: "2441ab9b85cbb65b61520dcf6c4948ef4f7e07619837f16aba5be8096a85c2b7",
  contentfulContentPreviewApiAccessToken: "50b5b3f0d534a2dd57fd06c260bf136ad5dc101fe226e9ab91f152a4ac2f5923",
  contentfulNotificationCheckoutPageEntryId: "UyXWVZIMAPtPIkSg9QvXa",
  contentfulNotificationCartPageEntryId: "01fgO0Gd2FdQdj4DRoQ6OF",
  contentfulSpaceId: "ufyps4xa8qu5",
  contentfulToken: "2441ab9b85cbb65b61520dcf6c4948ef4f7e07619837f16aba5be8096a85c2b7",
  cookiePrefix: globalConfig.isAcceptance ? "acc-karwei-nl" : "prd-karwei-nl",
  country: NL,
  defaultLocale: "nl",
  formulaCountry: "NL",
  id: "karwei_nl",
  locales: ["nl"],
  loyaltyCardPrefixes: ["2612", "2619"],
  suggestionApiKey: "3575eb1efa184932d3d9859e646e920f",
  theme,
  // @see serverConfig.proxy in vite.config.ts
  API_GATEWAY_URL: globalConfig.isLocalhost ? "/api-gateway/karwei_nl" : `https://api.${baseDomain}`,
  CHECKOUT_URL: globalConfig.isLocalhost ? "/checkout/karwei_nl" : `https://kassa.${baseDomain}`,
  MYACCOUNT_URL: globalConfig.isLocalhost ? "/myaccount/karwei_nl" : `https://mijn.${baseDomain}`,
  SHOPFRONT_URL: globalConfig.isLocalhost ? "/shopfront/karwei_nl" : `https://www.${baseDomain}`,
}
