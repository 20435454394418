import type { PaymentResultHandler } from "../../types"
import CheckoutPaymentResponseError from "../CheckoutPaymentResponseError"

export function handlePaymentResultFailed({ paymentResponse, i18n }: PaymentResultHandler) {
  if (
    paymentResponse.additionalData?.["additionalResponse.message"] ===
    "Failed to send message to POI. There may be a network issue or it may not have the websocket connected."
  ) {
    throw new CheckoutPaymentResponseError({
      i18nFallbackMessageKey: "checkout:PaymentError_payment_terminal_out_of_reach",
      paymentResponse,
      i18n,
    })
  }

  if (paymentResponse.errorCode === "order.info.incomplete.serviceconditions") {
    throw new CheckoutPaymentResponseError({
      i18nFallbackMessageKey: "checkout:PaymentError_order_info_incomplete",
      paymentResponse,
      i18n,
    })
  }

  if (paymentResponse.errorCode === "order.placeorder.not.enough.stock") {
    throw new CheckoutPaymentResponseError({
      i18nFallbackMessageKey: "checkout:PaymentError_not_enough_stock",
      paymentResponse,
      i18n,
    })
  }

  if (paymentResponse.errorCode === "giftcard.max.reached") {
    throw new CheckoutPaymentResponseError({
      i18nFallbackMessageKey: "checkout:PaymentError_giftcard_max_reached",
      paymentResponse,
      i18n,
    })
  }

  if (paymentResponse.errorCode === "order.info.invalid.billingAddress.email") {
    throw new CheckoutPaymentResponseError({
      i18nFallbackMessageKey: "checkout-common:NoValidEmail",
      fieldName: "billingAddress.email",
      paymentResponse,
      i18n,
    })
  }

  throw new CheckoutPaymentResponseError({
    i18nFallbackMessageKey: "checkout-common:CheckYourDetails",
    paymentResponse,
    i18n,
  })
}
