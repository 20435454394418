import { HandlerType, datadogLogs } from "@datadog/browser-logs"
import { formulaConfig } from "config/formula"
import { globalConfig } from "config/global"
import Cookies from "js-cookie"

export function initializeDatadogLogs() {
  datadogLogs.init({
    beforeSend: (event) => {
      if (event.http && event.http.status_code === 0) {
        // Request aborted. This is done either intentionally by us or due to
        // the user refreshing/navigating. We don't consider these as an error
        // that we should do something about.
        return false
      }

      // Remove hash from URL as it could lead to PII data.
      event.view.url = event.view.url.replace(/hash=[^&]*/, "hash=REDACTED")

      return true
    },
    clientToken: "pub8b7284d8119ef484c3b36d1031df7139",
    env: globalConfig.env,
    forwardErrorsToLogs: true,
    sessionSampleRate: 100,
    service: "checkout-frontend",
    site: "datadoghq.eu",
    trackSessionAcrossSubdomains: true,
    version: VERSION,
  })

  datadogLogs.setGlobalContext({
    brand: `${formulaConfig.brand}-${formulaConfig.country.toLowerCase()}`,
    formula: `${formulaConfig.brand}_${formulaConfig.country.toLowerCase()}`,
    preferredStore: Cookies.get("PREFERRED-STORE-UID"),
  })

  datadogLogs.logger.setLevel("info")
  datadogLogs.logger.setHandler(globalConfig.isLocalhost ? HandlerType.console : HandlerType.http)
}
