import { useQuery } from "@tanstack/react-query"
import { api } from "api"

export const QUERY_KEY_NUMBER_OF_PRODUCTS = "number-of-products"

export function useNumberOfProductsQuery() {
  return useQuery({
    queryKey: [QUERY_KEY_NUMBER_OF_PRODUCTS],
    queryFn: ({ signal }) => fetchNumberOfProducts({ signal }),
  })
}

interface FetchNumberOfProductsOptions {
  signal?: AbortSignal
}
async function fetchNumberOfProducts({ signal }: FetchNumberOfProductsOptions) {
  const res = await api.get<number>("/resources/cart/numberOfProducts", { signal })

  return res.data
}
