import { IntergammaToastContainer } from "@intergamma/global-styling"
import { Container } from "components/Container"
import { Footer } from "components/Footer"
import { Header } from "components/Header"
import { Main } from "components/Main"
import { Page } from "components/Page"
import { CheckoutForm } from "features/checkout/components/CheckoutForm"
import { useCheckoutForm } from "features/checkout/hooks/useCheckoutForm"
import { lazy } from "react"
import { FormProvider } from "react-hook-form"

const MultipartPayment = lazy(() =>
  import(/* webpackChunkName: "multipart-payment" */ "features/multipart-payment/components/MultipartPayment").then(
    (module) => ({
      default: module.MultipartPayment,
    })
  )
)

export function MultipartPaymentPage() {
  const form = useCheckoutForm()

  return (
    <Page moduleName="checkout">
      <Header variant="simplified" />
      <IntergammaToastContainer />
      <Main>
        <FormProvider {...form}>
          <Container>
            <CheckoutForm>
              <MultipartPayment />
            </CheckoutForm>
          </Container>
        </FormProvider>
      </Main>
      <Footer />
    </Page>
  )
}
