import { zodResolver } from "@hookform/resolvers/zod"
import { useEffect } from "react"
import { useForm } from "react-hook-form"
import type { IFullCheckoutFormValues, IFullCheckoutFormValuesWithMeta, PaymentMethodBrand } from "../types"
import { useCheckoutFormSchema } from "./useCheckoutFormSchema"
import { useDefaultCheckoutValuesQuery } from "./useDefaultCheckoutValuesQuery"

export function useCheckoutForm(paymentMethod?: PaymentMethodBrand) {
  const defaultCheckoutFormValuesQuery = useDefaultCheckoutValuesQuery(paymentMethod)
  const { getSchema } = useCheckoutFormSchema(paymentMethod)

  const form = useForm<IFullCheckoutFormValues>({
    values: defaultCheckoutFormValuesQuery.data as IFullCheckoutFormValuesWithMeta,
    resolver(values, context, options) {
      return zodResolver(getSchema(values))(values, context, options)
    },
    mode: "onTouched",
    resetOptions: {
      keepDirtyValues: true,
      keepErrors: true,
    },
  })

  const { reset, getValues, formState } = form

  // Ensure we reset the form after successfully submitting it.
  useEffect(() => {
    if (formState.isSubmitSuccessful) {
      reset(getValues())
    }
  }, [reset, getValues, formState.isSubmitSuccessful])

  return form
}
