import { emailRegex, getInvalidCharactersInEmail } from "features/checkout/validation/regex"
import type { TFunction } from "i18next"
import { z } from "zod"

export function email(t: TFunction<["checkout-common"]>) {
  return z
    .string({ required_error: t("checkout-common:FillInEmailAddress") })
    .min(1, t("checkout-common:FillInEmailAddress"))
    .max(256, `${t("checkout-common:EmailAddress")} ${t("checkout-common:IsTooLong").toLowerCase()}`)
    .superRefine((val, ctx) => {
      const invalidCharacters = getInvalidCharactersInEmail(val)

      if (invalidCharacters.length > 0) {
        const invalid = invalidCharacters.join("")
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: t("checkout-common:CharactersNotAllowed", { invalid }),
        })
      }

      if (!val.match(emailRegex)) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: t("checkout-common:NoValidEmail"),
        })
      }
    })
}
