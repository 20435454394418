import type { PaymentResponse } from "api/generated/checkout"
import type { i18n as i18next } from "i18next"
import { klarnaErrorCodes } from "./constants"

const stringToi18nKey = (str: string) => str && str.toLowerCase().split(".").join("_")

interface CheckoutPaymentResponseErrorOptions {
  i18nFallbackMessageKey?: string
  message?: string
  fieldName?: string
  paymentResponse: PaymentResponse
  i18n?: i18next
}
export default class CheckoutPaymentResponseError extends Error {
  fieldName?: string
  response: PaymentResponse

  constructor({
    fieldName,
    i18nFallbackMessageKey,
    message,
    paymentResponse,
    i18n,
  }: CheckoutPaymentResponseErrorOptions) {
    super()
    this.name = "PaymentResponseError"
    this.fieldName = fieldName
    this.message = message ?? this._getTranslatedErrorMessage(paymentResponse, i18nFallbackMessageKey, i18n)
    this.response = paymentResponse
  }

  _getTranslatedErrorMessage(
    { errorType, errorReason, errorCode }: { errorType?: string; errorReason?: string; errorCode?: string },
    i18nFallbackMessageKey?: string,
    i18n?: i18next
  ) {
    if (errorType === "klarna" && errorCode) {
      return klarnaErrorCodes[errorCode as keyof typeof klarnaErrorCodes]
    }
    if (!i18nFallbackMessageKey || !i18n) {
      return "An error occurred during payment"
    }

    if (errorReason && i18n.exists(`checkout:PaymentError_${stringToi18nKey(errorReason)}`)) {
      return i18n.t(`checkout:PaymentError_${stringToi18nKey(errorReason)}`)
    }

    if (errorCode && i18n.exists(`checkout:PaymentError_${stringToi18nKey(errorCode.toString())}`)) {
      return i18n.t(`checkout:PaymentError_${stringToi18nKey(errorCode.toString())}`)
    }

    return i18n.t(i18nFallbackMessageKey)
  }
}
