import { QueryClient } from "@tanstack/react-query"

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: 0,
      suspense: false,
      useErrorBoundary: false,
    },
    mutations: {
      useErrorBoundary: false,
    },
  },
})
