import { formulaConfig } from "config/formula"
import Cookies from "js-cookie"
import { useState } from "react"

type SpecialistStore = {
  uid: string
  name: string
}

export function useSpecialist() {
  const defaultStore = {
    uid: Cookies.get("SPECIALIST-STORE-UID") ?? "",
    name: Cookies.get("SPECIALIST-STORE-NAME") ?? "",
  }

  const [store, setStore] = useState(defaultStore.uid ? defaultStore : null)

  return {
    isSpecialist: !!store,
    store,
    setStore(store: SpecialistStore) {
      Cookies.set("SPECIALIST-STORE-UID", store.uid, {
        expires: 1,
        domain: formulaConfig.baseDomain,
        sameSite: "strict",
      })
      Cookies.set("SPECIALIST-STORE-NAME", store.name, {
        expires: 1,
        domain: formulaConfig.baseDomain,
        sameSite: "strict",
      })
      setStore(store)
    },
    removeStore() {
      Cookies.remove("SPECIALIST-STORE-UID")
      Cookies.remove("SPECIALIST-STORE-NAME")
      setStore(null)
    },
  }
}
