import { ACC, PRD } from "config/constants"

const currentDomain = window.location.hostname
const env = currentDomain.includes("acceptatie") ? ACC : PRD

export const globalConfig = {
  algoliaApplicationId: "4CI6R68JTI",
  env,
  isLocalhost: currentDomain === "localhost" || currentDomain.startsWith("kassa.local.acceptatie"),
  isAcceptance: env === ACC,
  isProduction: env === PRD,
} as const
