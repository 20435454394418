import { phoneFormats } from "@intergamma/validators"

export function splitPhoneNumber(value: string) {
  const format = Object.values(phoneFormats).find(
    ({ prefix }) => value.startsWith(`+${prefix}`) || value.startsWith(`00${prefix}`)
  )

  if (format) {
    const phoneNumber = value.replace(`+${format.prefix}`, "").replace(`00${format.prefix}`, "")

    return {
      country: format.cc,
      phoneNumber,
    }
  }

  return {
    country: undefined,
    phoneNumber: undefined,
  }
}
