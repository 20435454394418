import { sessionStorage } from "@intergamma/storage"
import { useCallback, useMemo } from "react"
import type { IFullCheckoutFormValues } from "../types"

const CHECKOUT_FORM_PERSIST_KEY = "checkout-form"

export function getPersistedCheckoutForm() {
  return sessionStorage.getJson(CHECKOUT_FORM_PERSIST_KEY) ?? {}
}

export function useCheckoutFormPersist() {
  const persisted = useMemo<Partial<IFullCheckoutFormValues>>(getPersistedCheckoutForm, [])

  const persist = useCallback((values: Partial<IFullCheckoutFormValues>) => {
    const payment = values.payment ?? {}

    sessionStorage.setJson(CHECKOUT_FORM_PERSIST_KEY, {
      billingAddress: values.billingAddress,
      hasShippingAddress: values.hasShippingAddress,
      shippingAddress: values.shippingAddress,
      // Ensure we don't persist multiPartReference and encrypted fields
      payment: {
        type: payment.type,
        ...(payment.type === "ideal" && { id: payment.id }),
        ...(payment.type === "giftcard" && { brand: payment.brand }),
        ...(payment.type === "bcmc" && { brand: payment.brand }),
      },
    })
  }, [])

  return {
    persisted,
    persist,
  }
}
