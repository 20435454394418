import type { PaymentAction } from "api/generated/checkout"
import type { BancontactMobileAction } from "types"

export function checkIsBancontactMobileRedirect(action: PaymentAction): action is BancontactMobileAction {
  if (!action) {
    return false
  }

  const { url, method, data } = action

  return method === "QR" && !!url && !!data
}
