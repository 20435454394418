import { openMopinionModal } from "features/mopinion"
import { useEffect, useState } from "react"

export function useMopinion() {
  const [loaded, setLoaded] = useState(() => !!window.srv)

  useEffect(() => {
    if (loaded) {
      return
    }

    function setMopinionLoaded() {
      setLoaded(true)
    }

    document.addEventListener("mopinion_loaded", setMopinionLoaded, { once: true })

    return () => {
      document.removeEventListener("mopinion_loaded", setMopinionLoaded)
    }
  }, [loaded])

  return {
    loaded,
    openModal: openMopinionModal,
  }
}
