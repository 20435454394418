import { datadogLogs } from "@datadog/browser-logs"
import { datadogRum } from "@datadog/browser-rum"
import type { ReactNode } from "react"
import { useEffect } from "react"

interface DatadogContextType {
  children: ReactNode
  context: Record<string, any>
}

export function DatadogContext({ children, context }: DatadogContextType) {
  const entries = Object.entries(context)

  useEffect(() => {
    for (const [key, value] of entries) {
      datadogLogs.setGlobalContextProperty(key, value)
      datadogRum.setGlobalContextProperty(key, value)
    }

    return () => {
      for (const [key] of entries) {
        datadogLogs.removeGlobalContextProperty(key)
        datadogRum.removeGlobalContextProperty(key)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(entries)])

  return children
}
