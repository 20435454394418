import { useQuery, useQueryClient } from "@tanstack/react-query"
import { api } from "api"
import { useCheckoutPageQuery } from "api/checkout-page"
import type { BeginCheckoutDispatchEventData } from "api/generated/checkout"
import type { AxiosRequestConfig } from "axios"
import { useCallback } from "react"

const QUERY_KEY = "begincheckout"

export function useTrackingBeginCheckout() {
  const checkoutPageQuery = useCheckoutPageQuery()

  return useQuery({
    enabled: !!checkoutPageQuery.data?.cartSummary, // wait for cart summary data to be available first
    queryKey: [QUERY_KEY],
    queryFn: ({ signal }) => fetchTrackingBeginCheckout({ signal }),
  })
}

export function useFetchTrackingBeginCheckout() {
  const queryClient = useQueryClient()
  return useCallback(() => {
    return queryClient.fetchQuery({
      queryKey: [QUERY_KEY],
      queryFn: ({ signal }) => fetchTrackingBeginCheckout({ signal }),
    })
  }, [queryClient])
}

async function fetchTrackingBeginCheckout(params: AxiosRequestConfig) {
  const response = await api.get<BeginCheckoutDispatchEventData>("/api/data/begincheckout", params)
  return response.data
}
