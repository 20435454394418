import { useQuery, useQueryClient } from "@tanstack/react-query"
import { api } from "api"
import type { AddPaymentInfoDispatchEventData } from "api/generated/checkout"
import type { AxiosRequestConfig } from "axios"
import { useCallback } from "react"

const QUERY_KEY = "addpaymentinfo"

type TrackingAddPaymentInfoParams = {
  shippingTierCustom?: string
  paymentType?: string
}

export function useTrackingAddPaymentInfo(params: TrackingAddPaymentInfoParams) {
  return useQuery({
    enabled: !!params.shippingTierCustom && !!params.paymentType,
    queryKey: [QUERY_KEY, params.shippingTierCustom, params.paymentType],
    queryFn: ({ signal }) => fetchAddPaymentInfo({ params, signal }),
  })
}

export function useFetchTrackingAddPaymentInfo() {
  const queryClient = useQueryClient()
  return useCallback(
    (params: TrackingAddPaymentInfoParams) => {
      return queryClient.fetchQuery({
        queryKey: [QUERY_KEY, params.shippingTierCustom, params.paymentType],
        queryFn: ({ signal }) => fetchAddPaymentInfo({ params, signal }),
      })
    },
    [queryClient]
  )
}

async function fetchAddPaymentInfo(config: AxiosRequestConfig) {
  const response = await api.get<AddPaymentInfoDispatchEventData>("/api/data/addpaymentinfo", config)
  return response.data
}
