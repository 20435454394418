import { buildQueryString } from "@intergamma/url"

interface AddFieldOptions {
  form: HTMLFormElement
  name: string
  value: string
}
function addField({ form, name, value }: AddFieldOptions) {
  const field = document.createElement("input")

  field.setAttribute("name", name)
  field.setAttribute("value", value)
  field.setAttribute("type", "hidden")
  form.appendChild(field)
}

interface Parameter {
  name: string
  key: string
  value: string
}
interface CreateFormOptions {
  url: string
  method: string
  fields?: any
  parameters?: Parameter[]
}
function createForm({ url, method = "POST", fields = {}, parameters = [] }: CreateFormOptions) {
  const form = document.createElement("form")

  form.setAttribute("action", url)
  form.setAttribute("method", method)
  parameters.forEach(({ name, key, value }) => addField({ form, name: key || name, value }))
  Object.keys(fields).forEach((name) => addField({ form, name, value: fields[name] }))
  document.body.appendChild(form)

  return form
}

interface ChangeLocationOptions {
  url: string
  fields?: any
  parameters?: Parameter[]
}
function changeLocation({ url, fields = {}, parameters = [] }: ChangeLocationOptions) {
  const [, path = "", fragment = ""] = (url && url.match(/([^#]+)(#.*)?/)) || []
  const separator = path.indexOf("?") === -1 ? "?" : "&"
  const params = parameters.reduce((acc, { name, key, value }) => ({ ...acc, [key || name]: value }), {})

  const query = buildQueryString({ ...fields, ...params })

  window.location.href = `${path}${query && separator + query}${fragment}`
}

interface RedirectToOptions {
  url: string
  method: string
  fields?: any
}
export function redirectTo(params: RedirectToOptions) {
  return !params.method || params.method.toUpperCase() === "GET" ? changeLocation(params) : createForm(params).submit()
}
