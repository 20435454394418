import { getKioskPaymentData } from "@intergamma/kiosk-util"
import { useMutation } from "@tanstack/react-query"
import { api } from "api"
import type { PaymentResponse, PlaceOrderRequest } from "api/generated/checkout"
import { ResponseNotOkError } from "lib/ResponseNotOkError"

// @todo Ensure generated types are corrected
type PaymentBody = Omit<PlaceOrderRequest, "hasAgreedToServiceCondition" | "payment"> & {
  hasAgreedToServiceCondition?: boolean
  payment?: Omit<PlaceOrderRequest["payment"], "additionalData"> & {
    additionalData?: Record<string, any>
  }
}

export function usePaymentMutation() {
  return useMutation({
    async mutationFn(values: PaymentBody) {
      const kioskData = await getKioskPaymentData()
      const paymentValues = { ...values }

      if (kioskData) {
        paymentValues.payment = {
          ...paymentValues.payment,
          ...kioskData,
        }
      }

      return api.post<PaymentResponse>("/api/payment", paymentValues, { withCredentials: true }).catch((err) => {
        if (err.response) {
          throw new ResponseNotOkError()
        }

        throw err
      })
    },
  })
}
