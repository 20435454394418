import { datadogLogs } from "@datadog/browser-logs"
import { isKiosk as checkIsKiosk } from "@intergamma/kiosk-util"
import type { IKioskData } from "lib/getKioskData"
import { getKioskData } from "lib/getKioskData"
import React, { useEffect, useState, type PropsWithChildren } from "react"

export enum DeviceType {
  App = "app",
  Browser = "browser",
  Kiosk = "kiosk",
}

const requestModes = {
  [DeviceType.App]: "APP_MODE",
  [DeviceType.Browser]: "DEFAULT_MODE",
  [DeviceType.Kiosk]: "KIOSK_MODE",
} as const

interface DeviceContextType {
  type: DeviceType | null
  kioskData: IKioskData | null
}

const DeviceContext = React.createContext<DeviceContextType>({ type: null, kioskData: null })
DeviceContext.displayName = "Device"

export function DeviceContextProvider({ children }: PropsWithChildren) {
  const [type, setType] = useState<DeviceContextType["type"]>(null)
  const [kioskData, setKioskData] = useState<DeviceContextType["kioskData"]>(null)

  useEffect(() => {
    setDeviceContext()

    async function setDeviceContext() {
      const deviceType = await getDeviceType()
      setType(deviceType)
      datadogLogs.setGlobalContextProperty("requestMode", requestModes[deviceType])

      if (deviceType === "kiosk") {
        setKioskData(await getKioskData())
      }
    }
  }, [])

  return <DeviceContext.Provider value={{ kioskData, type }}>{children}</DeviceContext.Provider>
}

export function useDeviceContext() {
  const context = React.useContext(DeviceContext)

  if (context === undefined) {
    throw new Error("useDeviceContext must be used within a DeviceContextProvider")
  }

  return context
}

async function getDeviceType() {
  if (window.navigator.userAgent.includes("IGApp")) {
    return DeviceType.App
  }

  if (await checkIsKiosk()) {
    return DeviceType.Kiosk
  }

  return DeviceType.Browser
}
