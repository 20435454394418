import { DevTool } from "@hookform/devtools"
import { useIsDebugEnabled } from "hooks/useIsDebugEnabled"
import { useTrackFormFieldState, useTrackFormFieldSubmitErrors } from "../hooks/adobe"
import { useTrackAddPaymentInfo } from "../hooks/tracking"
import { useCheckoutFormContext } from "../hooks/useCheckoutFormContext"
import { useCheckoutFormPersist } from "../hooks/useCheckoutFormPersist"
import { useCheckoutFormSubmit } from "../hooks/useHandleCheckoutFormSubmit"
import { useTrackAddShipmentInfo } from "../hooks/useTrackCheckoutSteps"
import type { PaymentMethodBrand } from "../types"

interface CheckoutFormProps {
  children: React.ReactNode
  paymentMethod?: PaymentMethodBrand
}

export function CheckoutForm({ children }: CheckoutFormProps) {
  const isDebugEnabled = useIsDebugEnabled() && false
  const form = useCheckoutFormContext()
  const { persist } = useCheckoutFormPersist()
  const onSubmit = useCheckoutFormSubmit()

  useTrackAddShipmentInfo()
  useTrackAddPaymentInfo()
  const trackFormFieldState = useTrackFormFieldState()
  const onInvalidTrackFormErrors = useTrackFormFieldSubmitErrors()

  return (
    <>
      <form
        className="flex flex-1 flex-col gap-8 md:gap-12"
        method="POST"
        id="checkout-form"
        noValidate
        onSubmit={form.handleSubmit(onSubmit, onInvalidTrackFormErrors)}
        onBlur={(event) => {
          persist(form.getValues())
          trackFormFieldState(event.target)
        }}
      >
        {children}
      </form>
      {isDebugEnabled && <DevTool control={form.control} placement="bottom-right" />}
    </>
  )
}
