import { getHeaders } from "@intergamma/account"
import type { SessionData } from "@intergamma/header"
import { useQuery } from "@tanstack/react-query"
import { myAccountApi } from "api"

export const QUERY_KEY_SESSION_DATA = "session-data"

export function useSessionDataQuery() {
  return useQuery({
    queryKey: [QUERY_KEY_SESSION_DATA],
    queryFn: () => fetchSessionData(),
  })
}

async function fetchSessionData() {
  const headers = await getHeaders()
  const response = await myAccountApi.get<SessionData>("/api/session-data", {
    withCredentials: true,
    headers,
  })
  return response.data
}
