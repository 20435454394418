import { IntergammaToastContainer } from "@intergamma/global-styling"
import { useCheckoutPageQuery } from "api/checkout-page"
import { useCurrentCustomerQuery } from "api/current-customer"
import { Container } from "components/Container"
import { Footer } from "components/Footer"
import { Header } from "components/Header"
import { Main } from "components/Main"
import { Page } from "components/Page"
import { useDefaultCheckoutValuesQuery } from "features/checkout/hooks/useDefaultCheckoutValuesQuery"
import { MopinionFeedback } from "features/mopinion/components"
import { Suspense, lazy } from "react"
import { ErrorBoundary } from "react-error-boundary"
import { useTranslation } from "react-i18next"

const BancontactPayment = lazy(() =>
  import(/* webpackChunkName: "bancontact-payment" */ "features/bancontact/components/BancontactPayment").then(
    (module) => ({
      default: module.BancontactPayment,
    })
  )
)

export function BancontactPage() {
  const { t } = useTranslation("checkout-common")
  const checkoutPageQuery = useCheckoutPageQuery()
  const currentCustomerQuery = useCurrentCustomerQuery()
  const defaultCheckoutValuesQuery = useDefaultCheckoutValuesQuery("bcmc")

  if (!checkoutPageQuery.isSuccess || !currentCustomerQuery.isSuccess || !defaultCheckoutValuesQuery.isSuccess) {
    return null
  }

  return (
    <Page moduleName="bancontact">
      <Header variant="simplified" />
      <IntergammaToastContainer />
      <Main>
        <Container>
          <div className="mx-[10px] my-[30px] md:mx-0 md:mb-0">
            <ErrorBoundary fallback={<p>{t("SomethingWentWrong")}</p>}>
              <Suspense fallback={null}>
                <BancontactPayment />
              </Suspense>
            </ErrorBoundary>
          </div>
        </Container>
        <MopinionFeedback className="mt-6" />
      </Main>
      <Footer />
    </Page>
  )
}
