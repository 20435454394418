import { useCheckoutPageQuery } from "api/checkout-page"
import { useMemo } from "react"

export function useHasServiceCondition() {
  const checkoutPageQuery = useCheckoutPageQuery()

  const cartSummaryHasService = useMemo(() => {
    if (!checkoutPageQuery.data?.cartSummary) {
      throw new Error("CartSummary data not available")
    }
    if (!checkoutPageQuery.data?.cartSummary.entries) {
      throw new Error("CartSummary entries are empty")
    }
    return checkoutPageQuery.data?.cartSummary.entries.some((entry) => entry.services && entry.services.length > 0)
  }, [checkoutPageQuery.data])

  return cartSummaryHasService
}
