import { useCheckoutPageQuery } from "api/checkout-page"
import { formulaConfig } from "config/formula"
import { useCustomerDetails } from "features/checkout/hooks/useCustomerDetails"
import { useMultipartPaymentData } from "features/multipart-payment/hooks/useMultipartPaymentData"
import { useCallback } from "react"
import { useTranslation } from "react-i18next"
import { z } from "zod"
import type { IFullCheckoutFormValues, PaymentMethodBrand } from "../types"
import * as customerDetails from "../validation/customerDetails"
import * as delivery from "../validation/delivery"
import * as payment from "../validation/payment"
import * as serviceCondition from "../validation/serviceCondition"
import { useHasServiceCondition } from "./useHasServiceCondition"

export function useCheckoutFormSchema(paymentMethod?: PaymentMethodBrand) {
  const { t } = useTranslation(["checkout", "checkout-common"])
  const hasServiceCondition = useHasServiceCondition()
  const multiPartPaymentData = useMultipartPaymentData()
  const checkoutPageQuery = useCheckoutPageQuery()
  const isLoggedIn = !!useCustomerDetails()

  const getSchema = useCallback(
    (values: IFullCheckoutFormValues) => {
      const shipments = checkoutPageQuery.data?.shipments ?? []

      // Special case
      if (multiPartPaymentData) {
        return z.object({
          payment: payment
            .schema(values?.payment, t)
            .and(z.object({ multiPartReference: payment.multiPartReference(t) })),
        })
      }

      // Special case
      if (paymentMethod === "bcmc") {
        return z.object({
          payment: payment.schema(values?.payment, t),
        })
      }

      return z.object({
        billingAddress: customerDetails.billingAddress(values?.billingAddress, t),
        ...((values.hasShippingAddress || values.billingAddress?.country !== formulaConfig.country) && {
          shippingAddress: customerDetails.shippingAddress(values?.shippingAddress, t),
        }),
        ...(isLoggedIn && {
          updateAccount: z.boolean(),
        }),
        delivery: delivery.schema(values?.delivery, t, shipments),
        ...(hasServiceCondition && {
          hasAgreedToServiceCondition: serviceCondition.hasAgreedToServiceCondition(t),
        }),
        payment: payment.schema(values?.payment, t),
      })
    },
    [isLoggedIn, checkoutPageQuery.data?.shipments, hasServiceCondition, multiPartPaymentData, paymentMethod, t]
  )

  return {
    getSchema,
  }
}
