import type { i18n as i18next } from "i18next"
import { ResponseNotOkError } from "lib/ResponseNotOkError"
import { log } from "lib/datadog-logging"
import type { UseFormReturn } from "react-hook-form"
import type { CheckoutFormValues, IFullCheckoutFormValuesWithMeta } from "../../types"
import CheckoutPaymentResponseError from "../CheckoutPaymentResponseError"

export interface HandlePaymentErrors {
  i18n: i18next
  values: CheckoutFormValues
  error: any
  form: UseFormReturn<IFullCheckoutFormValuesWithMeta, any>
}

export function handlePaymentErrors({ i18n, values, error, form }: HandlePaymentErrors) {
  let errorMessageForCustomer = i18n.t("checkout-common:WeCouldNotProcessYourOrder")

  const paymentLogInfo = {}

  // show errors from errorMap first
  const backendValidationError = error?.response?.errorMap?.["validation-failed"]?.at(0)
  if (backendValidationError) {
    form.setError("", {
      message: i18n.t([`checkout:${backendValidationError}`, "checkout-common:WeCouldNotProcessYourOrder"]),
    })
    log.error("payment-error", {
      errorName: error.name,
      errorDetails: error,
      submittedDeliveryData: "delivery" in values ? values.delivery : undefined,
      ...paymentLogInfo,
    })
    return
  }

  if (error?.name === "AbortError") {
    log.info(
      "payment-error",
      {
        errorName: "AbortError",
        ...paymentLogInfo,
      },
      error
    )
  } else if (error instanceof ResponseNotOkError) {
    log.error(
      "payment-error",
      {
        errorName: "FetchResponseNotOkError",
        errorDescription:
          "Caught a ResponseNotOkError. For more details see the network log entry (auto-captured by DataDog) related to this",
        submittedDeliveryData: "delivery" in values ? values.delivery : undefined,
        ...paymentLogInfo,
      },
      error
    )
  } else if (error instanceof CheckoutPaymentResponseError) {
    errorMessageForCustomer = error.message

    const isSolvableByCustomer =
      error.response.errorCode === "minimal.order.value" ||
      error.response.errorCode === "order.placeorder.not.enough.stock" ||
      error.response.errorCode === "card.no.balance" ||
      error.response.errorCode === "101_validation" || // Invalid card number
      error.response.errorCode === "payment.card.not.allowed" ||
      error.response.errorCode === "giftcard.max.reached" ||
      error.response.errorReason === "INVALID_DELIVERY_DATE"
    const logLevel = isSolvableByCustomer ? "info" : "error"

    log[logLevel]("payment-error", {
      errorName: error.name,
      errorDetails: error,
      submittedDeliveryData: "delivery" in values ? values.delivery : undefined,
      ...paymentLogInfo,
    })
  } else if (error instanceof Error) {
    errorMessageForCustomer = error.message

    log.error("payment-error", { errorName: "ScriptError", ...paymentLogInfo }, error)
  } else {
    log.error("payment-error", { errorName: "UnknownError", ...paymentLogInfo }, error)
  }

  if (error.fieldName) {
    form.setError(
      error.fieldName,
      error instanceof CheckoutPaymentResponseError ? error : { message: errorMessageForCustomer },
      { shouldFocus: true }
    )
    form.setError("", { message: i18n.t("checkout-common:CheckYourDetails") })

    return
  }

  form.setError("", error instanceof CheckoutPaymentResponseError ? error : { message: errorMessageForCustomer })
}
