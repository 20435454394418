import { useCheckoutPageQuery } from "api/checkout-page"
import { usePaymentMutation } from "api/payment"
import { BE, NL } from "config/constants"
import { usePagesConfig } from "hooks/usePagesConfig"
import { log } from "lib/datadog-logging"
import { useEffect, useRef } from "react"
import { useTranslation } from "react-i18next"
import { useNavigate } from "react-router-dom"
import { createCheckoutPaymentFailedPageUrl } from "../helpers/createUrl"
import { handlePayment } from "../helpers/handlePayment"
import { handlePaymentErrors } from "../helpers/paymentHandlers"
import { transformFormValues } from "../helpers/transformFormValues"
import type { IFullCheckoutFormValuesWithMeta } from "../types"
import {
  useDispatchTrackCheckoutDeliveryDateSubmit,
  useDispatchTrackCheckoutSubmitError,
  useDispatchTrackToPaymentProvider,
} from "./tracking"
import { useCheckoutFormContext } from "./useCheckoutFormContext"

const shouldRedirectResultCode = ["Expired"]
const shouldRedirectErrorCode = ["payment.lai.card.not.allowed"]

// FIXME Clean up
//
// How it should work:
// 1. Get necessary data
// 2. Return a submit handler
// 3. Make the payment request
// 4. Handle success response (redirect)
// 5. Handle error response
// 6. Setup debug logging
//
export function useCheckoutFormSubmit() {
  const form = useCheckoutFormContext()
  const paymentMutation = usePaymentMutation()
  const dispatchTrackToPaymentProviderEvent = useDispatchTrackToPaymentProvider()
  const dispatchTrackCheckoutSubmitError = useDispatchTrackCheckoutSubmitError()
  const dispatchTrackCheckoutDeliveryDateSubmit = useDispatchTrackCheckoutDeliveryDateSubmit()
  const checkoutPageQuery = useCheckoutPageQuery()

  const navigate = useNavigate()
  const { i18n, t } = useTranslation(["checkout-common", "checkout"])
  const pagesConfig = usePagesConfig()

  const paymentAttemptCountRef = useRef(0)
  const timeToPaymentStartTimeRef = useRef(Date.now())

  const cartSummary = checkoutPageQuery.data?.cartSummary
  const pickupStoreUid = form.watch("delivery.shipments.0.pickupStoreUid")
  const billingCountryFormValue = form.watch("billingAddress.country")
  const billingCountry =
    billingCountryFormValue === NL || billingCountryFormValue === BE ? billingCountryFormValue : undefined

  useEffect(() => {
    if (form.formState.submitCount > 0 && Object.entries(form.formState.errors).length > 0) {
      log.info("[RHF] form validation errors", { errors: form.formState.errors })
    }

    if (form.formState.submitCount > 0 && cartSummary?.pickupStore?.uid !== pickupStoreUid) {
      log.info("Store mismatch", { cartStore: cartSummary?.pickupStore?.uid, formStore: pickupStoreUid })
    }
  }, [cartSummary?.pickupStore?.uid, form, form.formState.errors, form.formState.submitCount, pickupStoreUid])

  return async (values: IFullCheckoutFormValuesWithMeta) => {
    try {
      const formValues = transformFormValues(values)
      const paymentResponse = await paymentMutation.mutateAsync(formValues)

      log.info("form-submit")
      dispatchTrackToPaymentProviderEvent()
      dispatchTrackCheckoutDeliveryDateSubmit()

      paymentAttemptCountRef.current += 1
      timeToPaymentStartTimeRef.current = Date.now()

      return handlePayment({
        billingCountry,
        cartUid: cartSummary?.cartUid,
        currencyIso: cartSummary?.totalWithTax?.currencyIso,
        paymentResponse: paymentResponse.data,
        navigate,
        i18n,
        form,
        t,
        logger(message: string) {
          log.info(message, {
            paymentStats: {
              paymentAttemptCount: paymentAttemptCountRef.current,
              timeToPaymentInMilliseconds: timeToPaymentStartTimeRef.current,
            },
            paymentMethodType: values?.payment?.type,
            response: JSON.stringify(paymentResponse),
          })
        },
        pagesConfig,
      })
    } catch (error: any) {
      dispatchTrackCheckoutSubmitError()
      handlePaymentErrors({ error, i18n, values, form })

      const resultCode = error.response?.resultCode
      const errorCode = error.response?.errorCode

      if (shouldRedirectResultCode.includes(resultCode) || shouldRedirectErrorCode.includes(errorCode)) {
        const checkoutRelativeUrl = pagesConfig.checkout.relativeUrl
        const url = createCheckoutPaymentFailedPageUrl(resultCode, checkoutRelativeUrl)
        navigate(url, { replace: true })
      }

      return
    }
  }
}
