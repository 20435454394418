import { dispatchAdobeEvent } from "@intergamma/adobe-tracking"
import { useCheckoutPageQuery } from "api/checkout-page"
import {
  useFetchTrackingAddPaymentInfo,
  useFetchTrackingBeginCheckout,
  useTrackingBeginCheckout,
  useTrackingToPaymentProvider,
} from "api/tracking"
import { differenceInDays, parseISO, startOfDay } from "date-fns"
import { getDateString } from "lib/calendar"
import { useCallback, useEffect, useRef } from "react"
import type { DeliveryMethodType } from "types"
import { useCheckoutFormContext } from "./useCheckoutFormContext"

export function useTrackBeginCheckout() {
  const hasSentEvent = useRef(false)
  const trackingQuery = useTrackingBeginCheckout()
  const checkoutPageQuery = useCheckoutPageQuery()
  const deliveryMethod = checkoutPageQuery.data?.cartSummary.delivery.type

  useEffect(() => {
    if (!hasSentEvent.current && trackingQuery.data) {
      hasSentEvent.current = true
      dispatchAdobeEvent({ type: "begin_checkout", data: trackingQuery.data })
      dispatchAdobeEvent({
        type: "checkout",
        data: {
          user_selected_element: "Default bezorgen of ophalen",
          user_selected_value: deliveryMethod === "ig-home" ? "Bezorgen" : "Ophalen",
        },
      })
    }
  }, [trackingQuery.data, deliveryMethod])
}

export function useTrackAddPaymentInfo() {
  const { watch, getValues } = useCheckoutFormContext()
  const paymentType = watch("payment.type")
  const fetchTrackingAddPaymentInfo = useFetchTrackingAddPaymentInfo()

  // dispatch an "add_payment_info" adobe event whenever a payment method is selected
  useEffect(() => {
    const shippingTierCustom = getValues("delivery.type")

    if (shippingTierCustom && paymentType) {
      fetchTrackingAddPaymentInfo({ shippingTierCustom, paymentType }).then((data) => {
        dispatchAdobeEvent({ type: "add_payment_info", data })
      })
    }
  }, [fetchTrackingAddPaymentInfo, getValues, paymentType])
}

export function useDispatchTrackToPaymentProvider() {
  const { watch } = useCheckoutFormContext()
  const [shippingTierCustom, paymentTypeCustom] = watch(["delivery.type", "payment.type"])
  const trackingQuery = useTrackingToPaymentProvider({ shippingTierCustom, paymentTypeCustom })

  return useCallback(() => {
    if (trackingQuery.data) {
      dispatchAdobeEvent({ type: "to_payment_provider", data: trackingQuery.data })
    }
  }, [trackingQuery.data])
}

export function useDispatchTrackCheckoutEmptyCart() {
  return useCallback(() => {
    dispatchAdobeEvent({ type: "checkout_error", data: { user_message_type: "checkout_cart_empty" } })
  }, [])
}

export function useDispatchTrackCheckoutSubmitError() {
  return useCallback(() => {
    dispatchAdobeEvent({ type: "checkout_error", data: { user_message_type: "checkout_submit_failed" } })
  }, [])
}

export function useDispatchTrackCheckoutDelivery() {
  const fetchTrackingBeginCheckout = useFetchTrackingBeginCheckout()

  return useCallback(
    (deliveryMethod: DeliveryMethodType) => {
      dispatchAdobeEvent({
        type: "checkout",
        data: {
          user_selected_element: "Ophalen of bezorgen",
          user_selected_value: deliveryMethod === "ig-home" ? "Bezorgen" : "Ophalen",
        },
      })
      fetchTrackingBeginCheckout().then((trackingData) => {
        dispatchAdobeEvent({ type: "choose_shipping_or_pickup", data: trackingData })
      })
    },
    [fetchTrackingBeginCheckout]
  )
}

export function useDispatchTrackCheckoutSelectedStore() {
  const fetchTrackingBeginCheckout = useFetchTrackingBeginCheckout()

  return useCallback(
    (index: number) => {
      dispatchAdobeEvent({
        type: "checkout",
        data: {
          user_selected_element: "Bouwmarkt wijziging",
          user_selected_value: `Index: ${index + 1}`,
        },
      })

      fetchTrackingBeginCheckout().then((trackingData) => {
        dispatchAdobeEvent({ type: "choose_shipping_or_pickup", data: trackingData })
      })
    },
    [fetchTrackingBeginCheckout]
  )
}

export function useDispatchTrackCheckoutDeliveryDateSelect() {
  const { getValues } = useCheckoutFormContext()
  const fetchTrackingBeginCheckout = useFetchTrackingBeginCheckout()
  const checkoutPageQuery = useCheckoutPageQuery()

  return useCallback(
    (value: string, index: number, defaultShiftDate: Date, shipmentIndex: number) => {
      const delivery = getValues("delivery")
      const numberOfShipments = delivery?.shipments?.length ?? 0
      const deliveryMethodValue = delivery?.type === "ig-home" ? "Bezorgen" : "Ophalen"
      const carrier = checkoutPageQuery.data?.shipments[shipmentIndex].carrierCode

      const startOfToday = startOfDay(new Date())
      const date = parseISO(value)
      const days = differenceInDays(date, startOfToday)
      const defaultDate = getDateString(defaultShiftDate)!
      const defaultDateDays = differenceInDays(parseISO(defaultDate), startOfToday) || 0

      dispatchAdobeEvent({
        type: "checkout",
        data: {
          user_selected_element: `${deliveryMethodValue} | datum wijziging`,
          user_selected_value: `Index: ${index + 1}`,
          user_message_value: `Defaultdatum: ${defaultDateDays} | Leverdatum: ${days} | ${carrier}`,
          user_message_type: `Datumselector: ${shipmentIndex + 1}/${numberOfShipments}`,
        },
      })

      fetchTrackingBeginCheckout().then((trackingData) => {
        dispatchAdobeEvent({ type: "choose_shipping_or_pickup", data: trackingData })
      })
    },
    [fetchTrackingBeginCheckout, getValues, checkoutPageQuery.data]
  )
}

export function useDispatchTrackCheckoutDeliveryDateSubmit() {
  const { getValues } = useCheckoutFormContext()
  const checkoutPageQuery = useCheckoutPageQuery()

  return useCallback(() => {
    const delivery = getValues("delivery")
    const shipmentsLength = delivery?.shipments?.length ?? 0

    delivery?.shipments?.forEach((shipment, shipmentIndex) => {
      const deliveryMethodValue = delivery.type === "ig-home" ? "Bezorgen" : "Ophalen"

      const defaultShiftDate = checkoutPageQuery.data?.shipments[shipmentIndex].defaultShiftDate
      const availableShiftDateOptions = checkoutPageQuery.data?.shipments[shipmentIndex].availableShifts.map((shift) =>
        getDateString(shift.date)
      )
      const selectedIndex = availableShiftDateOptions?.findIndex((date) => date === shipment.selectedShift) ?? 0
      const carrier = checkoutPageQuery.data?.shipments[shipmentIndex].carrierCode

      const startOfToday = startOfDay(new Date())
      const date = parseISO(shipment.selectedShift ?? "")
      const days = differenceInDays(date, startOfToday) || 0
      const defaultDate = getDateString(defaultShiftDate)!
      const defaultDateDays = differenceInDays(parseISO(defaultDate), startOfToday) || 0

      dispatchAdobeEvent({
        type: "checkout",
        data: {
          user_selected_element: `${deliveryMethodValue} | datum definitief`,
          user_selected_value: `Index: ${selectedIndex + 1}`,
          user_message_value: `Defaultdatum: ${defaultDateDays} | Leverdatum: ${days} | Carrier: ${carrier}`,
          user_message_type: `Datumselector: ${shipmentIndex + 1}/${shipmentsLength}`,
        },
      })
    }, [])
  }, [checkoutPageQuery.data, getValues])
}
