import { formulaConfig } from "config/formula"
import { useTranslation } from "react-i18next"

export function NotFoundPage() {
  const { i18n } = useTranslation()
  const languagePrefix = formulaConfig.country === "BE" ? `/${i18n.language.substring(0, 2)}/` : "/"

  window.location.assign(`${formulaConfig.SHOPFRONT_URL}${languagePrefix}404`)
  return null
}
