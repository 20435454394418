import type { TFunction } from "i18next"

export const countrySelectOptions = [
  "NL",
  "BE",
  "DE",
  "LU",
  "FR",
  "--",
  "BG",
  "CY",
  "DK",
  "EE",
  "FI",
  "GB",
  "GR",
  "HU",
  "IE",
  "IT",
  "HR",
  "LV",
  "LT",
  "MT",
  "AT",
  "PL",
  "PT",
  "RO",
  "SI",
  "SK",
  "ES",
  "CZ",
  "SE",
] as const
export const countrySelectOptionsWithoutNlAndBe = [
  "DE",
  "LU",
  "FR",
  "--",
  "BG",
  "CY",
  "DK",
  "EE",
  "FI",
  "GB",
  "GR",
  "HU",
  "IE",
  "IT",
  "HR",
  "LV",
  "LT",
  "MT",
  "AT",
  "PL",
  "PT",
  "RO",
  "SI",
  "SK",
  "ES",
  "CZ",
  "SE",
] as const

export type CountryCode = (typeof countrySelectOptions)[number]

export function getCountryNameByCountryCode(countryCode: CountryCode, t: TFunction<["checkout" | "checkout-common"]>) {
  if (countryCode === "--") {
    return "--"
  }
  const translationKey = `checkout:Countries.${countryCode}` as const
  return t([translationKey, "checkout:CountryFallback"], {
    countryCode,
  })
}
