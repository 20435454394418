import { IntergammaToastContainer } from "@intergamma/global-styling"
import { Footer } from "components/Footer"
import { Header } from "components/Header"
import { Main } from "components/Main"
import { Page } from "components/Page"
import { MopinionFeedback } from "features/mopinion/components"
import { lazy } from "react"

const Confirmation = lazy(() =>
  import(/* webpackChunkName: "confirmation" */ "./components/Confirmation").then((module) => ({
    default: module.Confirmation,
  }))
)

export function ConfirmationPage() {
  return (
    <Page moduleName="confirmation">
      <Header />
      <IntergammaToastContainer />
      <Main>
        <Confirmation />
        <MopinionFeedback className="mt-6" />
      </Main>
      <Footer />
    </Page>
  )
}
