import { DatadogContext } from "components/DatadogContext"
import { formulaConfig } from "config/formula"
import type { PageConfigKey } from "config/types"
import { useDeviceContext } from "context/device"
import { usePagesConfig } from "hooks/usePagesConfig"
import type { ReactNode } from "react"
import { useEffect } from "react"
import { useTranslation } from "react-i18next"

export interface PageProps {
  cartItemCount?: number
  children: ReactNode
  moduleName: PageConfigKey
}

export function Page({ moduleName, children }: PageProps) {
  const pageConfig = usePagesConfig()[moduleName]
  const device = useDeviceContext()
  const { t, ready: isTranslationLoaded, i18n } = useTranslation(["checkout-common"], { useSuspense: false })

  const pageTitle = `${formulaConfig.brandName} | ${isTranslationLoaded ? t(pageConfig.i18nKeys.pageTitle) : "..."}`
  const lang = i18n.isInitialized && i18n.language

  useEffect(() => {
    document.title = pageTitle
  }, [pageTitle])

  useEffect(() => {
    if (lang) {
      document.documentElement.setAttribute("lang", lang)
    }
  }, [lang])

  return (
    <DatadogContext context={{ module: moduleName, deviceType: device.type, kioskData: device.kioskData }}>
      <div className="flex min-h-screen flex-col bg-white">{children}</div>
    </DatadogContext>
  )
}
