import { Page } from "components/Page"
import { lazy } from "react"

const TerminalPaymentInterstitial = lazy(() =>
  import(/* webpackChunkName: "terminal-payment-interstitial" */ "./components/TerminalPaymentInterstitial").then(
    (module) => ({
      default: module.TerminalPaymentInterstitial,
    })
  )
)

export function TerminalPaymentInterstitialPage() {
  return (
    <Page moduleName="terminalPaymentInterstitial">
      <TerminalPaymentInterstitial />
    </Page>
  )
}
