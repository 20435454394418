import { isKiosk as checkIsKiosk, getKioskData as getKioskDataFromUtil } from "@intergamma/kiosk-util"

export interface IKioskData {
  isKiosk: boolean
  store: string | null
  storeDepartment: string | null
}
// note: this is a polyfill for getKioskData from @intergamma/kiosk-util
// it will return the same data (when available) for older Kiosk devices that don't
// run the Kiosk extension yet
export const getKioskData = async (): Promise<IKioskData> => {
  const isKiosk = await checkIsKiosk()

  if (!isKiosk) {
    return {
      isKiosk,
      store: null,
      storeDepartment: null,
    }
  }

  // This data is only available through the new Kiosk with extension
  const kioskData = await getKioskDataFromUtil()

  if (kioskData.isKiosk) {
    return {
      isKiosk,
      store: kioskData.store,
      storeDepartment: kioskData.storeDepartment,
    }
  }

  const userAgent = window.navigator.userAgent
  const kioskUserAgentParts = userAgent.split("-")

  return {
    isKiosk,
    store: kioskUserAgentParts[7],
    storeDepartment: kioskUserAgentParts[8],
  }
}
