import type { PaymentAction } from "api/generated/checkout"
import { redirectTo } from "helpers/redirectTo"
import { checkIsBancontactMobileRedirect } from "./checkIsBancontactMobileRedirect"

export function handleRedirect(action: PaymentAction) {
  const { url, method } = action

  if (!url || !method) {
    throw new Error("Redirect failed because url or method was undefined")
  }

  if (!checkIsBancontactMobileRedirect(action)) {
    const { data } = action

    redirectTo({ url, method, fields: data })
  } else {
    redirectTo({ url, method })
  }
}
