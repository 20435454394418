import { dispatchAdobeEvent } from "@intergamma/adobe-tracking"
import { Button as OldButton } from "@intergamma/button"
import { useActiveVariant, useVariants } from "@intergamma/experiments/hooks"
import { Button as NewButton } from "@intergamma/ui/button"
import { useMopinion } from "features/mopinion/hooks"
import { log } from "lib/datadog-logging"
import { useEffect } from "react"
import type { FallbackProps } from "react-error-boundary"
import { ErrorBoundary } from "react-error-boundary"
import { Trans, useTranslation } from "react-i18next"
import { WarningIcon } from "./icons/WarningIcon"

type GenericErrorBoundaryProps = {
  children?: React.ReactNode
}

export function GenericErrorBoundary(props: GenericErrorBoundaryProps) {
  return <ErrorBoundary FallbackComponent={FallbackComponent}>{props.children}</ErrorBoundary>
}

function FallbackComponent(props: FallbackProps) {
  const { t } = useTranslation("checkout-common")
  const mopinion = useMopinion()
  const { AControl, BNew } = useVariants("abtest_dba_1971-disabled-dba_4")
  const activeVariant = useActiveVariant("abtest_button_shapes-ew_8")

  useEffect(() => {
    dispatchAdobeEvent({ type: "checkout_error", data: { user_message_type: "checkout_unhandled_error" } })
    log.error("[checkout] GenericErrorBoundary", {}, props.error)
  }, [props.error])

  return (
    <div className="flex flex-col items-center gap-4 px-4 py-8 text-center">
      <WarningIcon className="text-[#cd4926]" />
      <h1 className="text-200/6 font-bold antialiased">{t("GenericErrorTitle")}</h1>
      <p>{t("GenericErrorMessage")}</p>
      <AControl>
        <OldButton onClick={props.resetErrorBoundary} $experimentalBorderRadius={activeVariant === "B-variant"}>
          {t("GenericErrorReloadPage")}
        </OldButton>
      </AControl>
      <BNew>
        <NewButton onClick={props.resetErrorBoundary} experimentalBorderRadius>
          {t("GenericErrorReloadPage")}
        </NewButton>
      </BNew>
      {mopinion.loaded && (
        <p>
          <Trans t={t} i18nKey="GenericErrorReceiveFeedback">
            Als dit het probleem niet verhelpt, ontvangen we graag je
            <button className="underline" role="button" tabIndex={0} onClick={mopinion.openModal}>
              feedback
            </button>
          </Trans>
        </p>
      )}
    </div>
  )
}
