import { gamma_be as theme } from "@intergamma/theme"
import { BE, GAMMA } from "config/constants"
import { globalConfig } from "config/global"
import type { BaseFormulaConfig } from "config/types"

export type GammaBECookiePrefix = "www-gamma-be" | "acc-gamma-be"
export type GammaBEBaseDomain = "gamma.be" | "acceptatie.gamma.be"

const baseDomain = globalConfig.isAcceptance ? "acceptatie.gamma.be" : "gamma.be"

export const gamma_be: BaseFormulaConfig<GammaBEBaseDomain, GammaBECookiePrefix> = {
  baseDomain,
  brand: GAMMA,
  brandName: "GAMMA",
  contentfulCartDeliveryInformationEntryId: "2DdMix0Jf94dNuwVcaecSP",
  contentfulConfirmationDifmInfoEntryId: "",
  contentfulContentDeliveryApiAccessToken: "bdbdeba23f7ce4133a995c715a38bb2e161163a579cb925a27a2d3f4e44c9e76",
  contentfulContentPreviewApiAccessToken: "0df0d8e026869651f8bff80d5c21c416368761b2595623f0356c9c5f3d61dfd5",
  contentfulNotificationCheckoutPageEntryId: "3GxIkS41RyX9ghudNwpIfu",
  contentfulNotificationCartPageEntryId: "2wOGHw6nLiQSCGCZgcSz5z",
  contentfulSpaceId: "2oqwvxysf41a",
  contentfulToken: "bdbdeba23f7ce4133a995c715a38bb2e161163a579cb925a27a2d3f4e44c9e76",
  cookiePrefix: globalConfig.isAcceptance ? "acc-gamma-be" : "www-gamma-be",
  country: BE,
  defaultLocale: "nl-BE",
  formulaCountry: "BE",
  id: "gamma_be",
  locales: ["nl-BE", "fr-BE"],
  loyaltyCardPrefixes: ["2613", "2614", "2617", "2618", "2629"],
  suggestionApiKey: "4357fc250fdcb2aedd382b560d7715a4",
  theme,
  // @see serverConfig.proxy in vite.config.ts
  API_GATEWAY_URL: globalConfig.isLocalhost ? "/api-gateway/gamma_be" : `https://api.${baseDomain}`,
  CHECKOUT_URL: globalConfig.isLocalhost ? "/checkout/gamma_be" : `https://kassa.${baseDomain}`,
  MYACCOUNT_URL: globalConfig.isLocalhost ? "/myaccount/gamma_be" : `https://mijn.${baseDomain}`,
  SHOPFRONT_URL: globalConfig.isLocalhost ? "/shopfront/gamma_be" : `https://www.${baseDomain}`,
}
