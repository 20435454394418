import { sessionStorage } from "@intergamma/storage"
import type { PaymentResultHandler } from "../types"
import CheckoutPaymentResponseError from "./CheckoutPaymentResponseError"
import * as paymentHandlers from "./paymentHandlers"

export function handlePayment(data: PaymentResultHandler): PaymentResultHandler {
  if (
    data.paymentResponse.resultCode &&
    ["Authorised", "Pending", "Received", "RedirectShopper", "PaymentStarted"].includes(data.paymentResponse.resultCode)
  ) {
    const { payment, ...formValuesWithoutPayment } = data.form.getValues()

    if (data.paymentResponse.multiPartReference) {
      sessionStorage.setJson(data.paymentResponse.multiPartReference, {
        cardPayments: data.paymentResponse.cardPayments,
        expectedDelivery: formValuesWithoutPayment.delivery,
      })
    }
  }

  switch (data.paymentResponse.resultCode) {
    case "Authorised":
    case "Pending":
    case "Received":
      paymentHandlers.handlePaymentResultReceived(data)
      break
    case "RedirectShopper":
      paymentHandlers.handlePaymentResultRedirectShopper(data)
      break
    case "PaymentStarted":
      if (!data.paymentResponse.terminalPaymentInfo) {
        throw new Error("Missing terminalPaymentInfo for payment")
      }

      data.navigate(
        `${data.pagesConfig.terminalPaymentInterstitial.relativeUrl}/${data.paymentResponse.terminalPaymentInfo.orderCode}`
      )
      break
    case "Cancelled":
      throw new CheckoutPaymentResponseError({
        i18nFallbackMessageKey: "checkout-common:PaymentIsCancelled",
        paymentResponse: data.paymentResponse,
        i18n: data.i18n,
      })
    case "Failed":
      paymentHandlers.handlePaymentResultFailed(data)
      break
    case "Refused":
      throw new CheckoutPaymentResponseError({
        i18nFallbackMessageKey: "checkout-common:PaymentIsDeclined",
        paymentResponse: data.paymentResponse,
        i18n: data.i18n,
      })
    default:
      throw new CheckoutPaymentResponseError({
        i18nFallbackMessageKey: "checkout-common:PaymentWentWrong",
        paymentResponse: data.paymentResponse,
        i18n: data.i18n,
      })
  }

  return data
}
