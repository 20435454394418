// Runtime config related constants
export const KARWEI = "karwei" as const
export const GAMMA = "gamma" as const
export const NL = "NL" as const
export const BE = "BE" as const
export const PRD = "prd" as const
export const ACC = "acc" as const

// Shipment types aka route type
export const SHIPMENT_TYPE_CLICK_AND_COLLECT = "CLICK_AND_COLLECT"
export const SHIPMENT_TYPE_STORE_BACKORDER = "STORE_BACKORDER" // aka GrAss (Groot Assortiment)

// Carriers
export const SHIPMENT_CARRIER_COTAP_POST_NL = "COTAP_POST_NL"
export const SHIPMENT_CARRIER_DHB = "DHB"
export const SHIPMENT_CARRIER_EEKHO_POST_NL = "EEKHO_POST_NL"
export const SHIPMENT_CARRIER_GOUOL = "GOUOL"
export const SHIPMENT_CARRIER_HAMIP = "HAMIP"
export const SHIPMENT_CARRIER_MEUBA_POST_NL = "MEUBA_POST_NL"
export const SHIPMENT_CARRIER_POST_NL = "POST_NL"
export const SHIPMENT_CARRIER_SHIP_FROM_STORE_CARRIER = "SHIP_FROM_STORE_CARRIER"
export const SHIPMENT_CARRIER_VIA_POST_NL = "VIA_POST_NL"

export const HOME_DELIVERY = "ig-home"
export const STORE_DELIVERY = "ig-store"
