import type { ConfirmationData } from "api/generated/checkout"
import type { PagesConfig } from "config/types"
import type { PaymentFailureReasonCode } from "features/checkout/types"

export function createConfirmationPageUrl(
  { orderCode, orderHash, paymentMethod, paymentResult }: ConfirmationData,
  checkoutConfirmationRelativeUrl: PagesConfig["confirmation"]["relativeUrl"]
) {
  if (!orderCode || !orderHash || !paymentMethod || !paymentResult) {
    throw new Error("Confirmation data is missing")
  }

  const searchParams = new URLSearchParams([
    ["hash", orderHash],
    ["orderNumber", orderCode],
    ["authResult", paymentResult],
    ["paymentMethod", paymentMethod],
  ])

  return `${checkoutConfirmationRelativeUrl}?${searchParams}`
}

export function createCheckoutPaymentFailedPageUrl(
  paymentFailureReasonCode: PaymentFailureReasonCode,
  checkoutRelativeUrl: PagesConfig["checkout"]["relativeUrl"]
) {
  return `${checkoutRelativeUrl}?error=${paymentFailureReasonCode}`
}
