import { useCurrentCustomerQuery } from "api/current-customer"
import type { MappedAuthenticatedCustomer } from "features/checkout/types"
import { isNil, omitBy } from "lodash-es"
import { useMemo } from "react"
import type { AuthenticatedCustomer, AuthenticatedCustomerWithPreferredStore, CurrentCustomer } from "types"

export function useCustomerDetails() {
  const currentCustomerQuery = useCurrentCustomerQuery()

  const authenticatedCustomer = useMemo(() => {
    if (!isAuthenticatedCustomer(currentCustomerQuery.data)) {
      return
    }

    const authenticatedCustomer = mapAuthenticatedCustomerData(currentCustomerQuery.data)

    return omitBy(
      authenticatedCustomer,
      (value) => isNil(value) || value === ""
    ) as Partial<MappedAuthenticatedCustomer>
  }, [currentCustomerQuery.data])

  return authenticatedCustomer
}

function isAuthenticatedCustomer(
  customer: CurrentCustomer | undefined
): customer is AuthenticatedCustomer | AuthenticatedCustomerWithPreferredStore {
  return !!customer && "customerId" in customer && customer.customerId.length > 0
}

function mapAuthenticatedCustomerData({
  address,
  ...authenticatedCustomer
}: AuthenticatedCustomer): MappedAuthenticatedCustomer {
  return {
    ...authenticatedCustomer,
    ...address,
    loyaltyCardNumber: authenticatedCustomer.defaultLoyaltyCardNumber,
    municipality: address?.postalCode && address?.city ? `${address.postalCode} ${address.city}` : undefined,
  }
}
