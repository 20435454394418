import { u as c } from "./useActiveVariant-E1LtEXNi.js";
import { useMemo as e } from "react";
import { experiments as o } from "./config.js";
function u(t) {
  if (!(t in o))
    throw Error(`Experiment "${t}" not found`);
  const n = o[t];
  return Object.entries(n.variants).reduce(
    (s, [r]) => ({ ...s, [r]: r }),
    {}
  );
}
function v(t) {
  const n = u(t), i = c(t);
  return e(
    () => Object.keys(n).reduce(
      (s, r) => ({
        ...s,
        [p(r)]({ children: a }) {
          return r === i || r.startsWith("A-") && i === void 0 ? a : null;
        }
      }),
      {}
    ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [t]
  );
}
function p(t) {
  function n(r) {
    return r.charAt(0).toUpperCase() + r.slice(1);
  }
  return t.split("-").map(n).join("");
}
export {
  c as useActiveVariant,
  v as useVariants,
  u as useVariantsOf
};
