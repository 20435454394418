import { BancontactPage } from "features/bancontact/page"
import { CartPage } from "features/cart/page"
import { CheckoutPage } from "features/checkout/page"
import { ConfirmationPage } from "features/confirmation/page"
import { MultipartPaymentPage } from "features/multipart-payment/page"
import { NotFoundPage } from "features/not-found"
import { RentalConfirmationPage } from "features/rental-confirmation/page"
import { RentalPage } from "features/rental/page"
import { SpecialistPage } from "features/specialist/page"
import { TerminalPaymentInterstitialPage } from "features/terminal-payment-interstitial/page"
import type { PagesConfig } from "./types"

/**
 * IMPORTANT: Do not edit any path names without prior approval from the Web Analytics team.
 * If a name change is required, inform the Web Analytics team first
 * to ensure consistency and proper handling of the changes.
 */
export const pages: PagesConfig = {
  cart: {
    i18nKeys: {
      pageTitle: "checkout-common:Cart",
    },
    relativeUrl: "/cart",
    path: "cart",
    element: <CartPage />,
  },
  checkout: {
    i18nKeys: {
      pageTitle: "checkout-common:DeliverAndPay",
    },
    relativeUrl: "/",
    path: "/",
    element: <CheckoutPage />,
  },
  confirmation: {
    i18nKeys: {
      pageTitle: "checkout-common:YourOrder",
    },
    relativeUrl: "/confirmation",
    path: "confirmation",
    element: <ConfirmationPage />,
  },
  terminalPaymentInterstitial: {
    i18nKeys: {
      pageTitle: "checkout-common:TerminalPaymentInterstitialPageTitle",
    },
    relativeUrl: "/checkout/paymentTerminalInterstitial",
    path: "checkout/paymentTerminalInterstitial/:orderCode",
    element: <TerminalPaymentInterstitialPage />,
  },
  bancontact: {
    i18nKeys: {
      pageTitle: "checkout-common:BancontactPaymentPageTitle",
    },
    relativeUrl: "/checkout/bancontact",
    path: "checkout/bancontact",
    element: <BancontactPage />,
  },
  multipartPayment: {
    i18nKeys: {
      pageTitle: "checkout-common:YourOrder",
    },
    relativeUrl: "/checkout/multipartPayment",
    path: "checkout/multipartPayment",
    element: <MultipartPaymentPage />,
  },
  rentalConfirmation: {
    i18nKeys: {
      pageTitle: "checkout-common:Cart",
    },
    relativeUrl: "/rental/confirmation",
    path: "rental/confirmation",
    element: <RentalConfirmationPage />,
  },
  rental: {
    i18nKeys: {
      pageTitle: "checkout-common:RentalReservationConfirmation",
    },
    relativeUrl: "/rental/reservation",
    path: "rental/reservation",
    element: <RentalPage />,
  },
  specialist: {
    i18nKeys: {
      pageTitle: "checkout-common:Specialist",
    },
    relativeUrl: "/specialist",
    path: "specialist",
    element: <SpecialistPage />,
  },
  notFound: {
    i18nKeys: {
      pageTitle: "checkout-common:NotFoundPageTitle",
    },
    relativeUrl: "/404",
    path: "*",
    element: <NotFoundPage />,
  },
}
