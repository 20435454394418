import { formulaConfig } from "config/formula"
import type {
  CartMutationResponseEntryError,
  CartResponse,
  CartResponseActionCode,
  CartResponseEntry,
  CartResponseEntrySticker,
  MappedCartResponse,
  MappedCartResponseEntry,
} from "features/cart/types"
import { getDamImageSrc, getDamImageSrcSet } from "lib/damImage"
import { log } from "lib/datadog-logging"

export function mapCart(cart: CartResponse): MappedCartResponse {
  const totalMissedLoyaltyDiscountValue =
    cart.subTotalWithLoyaltyPromotions?.value > 0 ? cart.subTotal.value - cart.subTotalWithLoyaltyPromotions.value : 0

  return {
    uid: cart.uid,
    deliveryModes: getSortedDeliveryModes(cart),
    entries: getEntries(cart),
    entriesWithLoyaltyPromotions: cart.entriesWithLoyaltyPromotions,
    isGuestCart: cart.isGuestCart,
    cartItemCount: cart.entries.map((entry) => entry.quantity).reduce((a, b) => a + b, 0),
    priceEntriesSubTotal: cart.entriesSubTotal?.value,
    priceSubTotal: cart.subTotal.value,
    loyaltyCardNumber: cart.loyaltyCardNumber,
    isLoyaltyCardNumberInvalid: checkLoyaltyCardNumberInvalid(cart),
    isLoyaltyPointsFeatureEnabled: cart.isLoyaltyEnabled,
    isUsingLoyaltyPointsForDiscount: cart.loyaltyInfo?.isUsingLoyaltyPointsForDiscount ?? false,
    loyaltyPointBalance: cart.loyaltyInfo?.currentPoints ?? 0,
    loyaltyPointsToBurn: cart.loyaltyInfo?.pointsToBurn ?? 0,
    loyaltyPointsToEarn: cart.loyaltyInfo?.pointsToEarn ?? 0,
    loyaltyDiscountValue: cart.loyaltyInfo?.loyaltyDiscount?.value ?? null,
    isSavingLoyaltyPoints: cart.loyaltyInfo?.isSavingLoyaltyPoints ?? false,
    appliedActionCodes: cart.appliedActionCodes.map(mapAppliedActionCode).sort((a, b) => (a.code > b.code ? 1 : -1)),
    actionCodeInvalidError: cart.cartErrors?.find(({ errorType }) => errorType === "action-code-apply-failed"),
    validationErrors: cart.validationErrors,
    discountValues: cart.discountValues,
    totalDiscounts: cart.totalDiscounts,
    totalLoyaltyDiscount: cart.totalLoyaltyDiscount,
    cartErrors: cart.cartErrors,
    cartEntryErrors: cart.cartEntryErrors,
    totalMissedLoyaltyDiscount: {
      currencyIso: "EUR",
      value: totalMissedLoyaltyDiscountValue > 0 ? totalMissedLoyaltyDiscountValue : 0,
    },
    totalNonLoyaltyDiscount: cart.totalNonLoyaltyDiscount,
    localApproachInsulationAmount: cart.localApproachInsulationAmount,
    highestMissingLoyaltySticker: cart.highestMissingLoyaltySticker,
    orderValueLimitInfo: cart.orderValueLimitInfo,
    totalWithLoyaltyPromotions: cart.totalWithLoyaltyPromotions,
    subTotalWithLoyaltyPromotions: cart.subTotalWithLoyaltyPromotions,
  }
}

function createSticker(
  { stickerBarcode, percentage, appliedAtSku }: CartResponseEntrySticker,
  entrySku: CartResponseEntry["sku"]
) {
  return {
    barcode: stickerBarcode,
    discountPercentage: percentage,
    isApplied: appliedAtSku === entrySku,
  }
}

function mapCartEntry(entry: CartResponseEntry, errors?: CartMutationResponseEntryError[]): MappedCartResponseEntry {
  const celumId = entry.product.image?.celumId ?? undefined
  const imageUrl = entry.product.image?.url ?? undefined

  if (!celumId && !imageUrl) {
    log.warn("Missing product image", {
      sku: entry.sku,
      productName: entry.product.name,
    })
  }

  const quantityMinimum = entry.product.quantityMinimum
  const quantityMaximum = entry.product.quantityMaximum
  const quantityStepSize = entry.product.quantityStepSize || 1
  const quantityDisabled = Number.isInteger(quantityMaximum) && quantityMinimum === quantityMaximum

  return {
    deliveryPromise: entry.deliveryPromise,
    sku: entry.sku,
    imageSrc: celumId ? getDamImageSrc({ celumId, format: 23 }) : imageUrl,
    imageSrcSet: celumId ? getDamImageSrcSet({ celumId }) : undefined,
    name: entry.product.name,
    quantity: entry.quantity,
    quantityMinimum: quantityMinimum,
    quantityMaximum: quantityMaximum,
    quantityStepSize: quantityStepSize,
    quantityDisabled: quantityDisabled,
    totalPrice: entry.totalPrice.value,
    basePrice: entry.basePrice.value,
    nonDiscountedTotalPrice: entry.nonDiscountedTotalPrice.value,
    nonDiscountedTotalPriceSource: entry.nonDiscountedTotalPriceSource,
    url: formulaConfig.SHOPFRONT_URL + entry.product.url,
    errors,
    billOfMaterials: entry.product.billOfMaterials,
    configuredProduct: entry.product.configuredProduct,
    stickers: entry.stickers?.map((sticker) => createSticker(sticker, entry.sku)) ?? [],
    isClickAndCollectDelivery: entry.clickAndCollectDelivery,
    services: entry.services,
    discountValues: entry.discountValues,
    missedDiscountValues: entry.missedDiscountValues,
    localApproachInsulation: entry.product.localApproachInsulation,
    totalPriceWithMissedLoyaltyDiscount: entry.totalPriceWithMissedLoyaltyDiscount,
    appliedStickerCode: entry.appliedStickerCode,
    defaultDiscountValue: entry.defaultDiscountValue,
    entryNumber: entry.entryNumber,
  }
}

function mapAppliedActionCode({ actionCode, actionCodeMessage, percentage, discount }: CartResponseActionCode) {
  return {
    code: actionCode,
    description: actionCodeMessage,
    discountPercentage: percentage,
    // We extract the value as a float as we receive it as a string. This is a workaround
    // @see https://intergamma.atlassian.net/browse/DBA-935
    discountValue: parseFloat(discount.replace(/^\D+/g, "")),
  }
}

function getSortedDeliveryModes(data: CartResponse) {
  return data.deliveryModes.sort(
    ({ code: firsDeliveryCode }, { code: secondDeliveryCode }) =>
      firsDeliveryCode.localeCompare(secondDeliveryCode) * -1
  )
}

function checkLoyaltyCardNumberInvalid(data: CartResponse) {
  return (
    data.cartErrors?.some(
      ({ errorType, cause: { errorType: causeType } }) =>
        errorType === "loyalty-card-not-linked" && causeType === "loyalty-card-number-invalid"
    ) ?? false
  )
}

function getEntries(data: CartResponse) {
  return data.entries.map((entry) => {
    const errors = data.cartEntryErrors?.[entry.sku]
    return mapCartEntry(entry, errors)
  })
}
