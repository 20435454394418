import { dispatchAdobeEvent } from "@intergamma/adobe-tracking"
import { useCheckoutPageQuery } from "api/checkout-page"
import { useTrackingAddShippingInfo } from "api/tracking"
import { useEffect, useRef } from "react"
import { useCheckoutFormContext } from "./useCheckoutFormContext"

const formSections = [
  {
    name: "billingAddress",
    trackingActionName: "checkoutstep2",
    eventType: "add_shipping_info",
  },
] as const

function checkUserHasVisitedSection(sectionName: string, fieldToVisitedStateMap?: Record<string, any>): boolean {
  if (!fieldToVisitedStateMap) {
    return false
  }

  const hasVisitedFieldInSection = Object.keys(fieldToVisitedStateMap)
    .filter((fieldName) => fieldName.startsWith(sectionName))
    .some((fieldName) => fieldToVisitedStateMap[fieldName])

  return hasVisitedFieldInSection
}

export function useTrackAddShipmentInfo() {
  const { formState } = useCheckoutFormContext()
  const checkoutPageQuery = useCheckoutPageQuery()
  const watchedSections = useRef([...formSections])

  const shippingTier = checkoutPageQuery.data?.cartSummary.delivery?.type
  const trackingAddShippingQuery = useTrackingAddShippingInfo({ shippingTier })

  const stringifiedDirtyFields = JSON.stringify(formState.dirtyFields)

  useEffect(() => {
    if (watchedSections.current.length === 0) return

    watchedSections.current.forEach(({ name, eventType }) => {
      if (checkUserHasVisitedSection(name, formState.dirtyFields)) {
        const data = trackingAddShippingQuery.data
        if (data) {
          dispatchAdobeEvent({ type: eventType, data })
          watchedSections.current = watchedSections.current.filter((section) => section.name !== name)
        }
      }
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stringifiedDirtyFields, trackingAddShippingQuery.data])
}
