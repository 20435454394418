import { dispatchAdobeEvent } from "@intergamma/adobe-tracking"
import type { ViewCartDispatchEventData } from "api/generated/checkout"
import { useTrackingViewCart } from "api/tracking"
import { getCartEntryMutationValue } from "helpers/getCartEntryMutationValue"
import { useEffect } from "react"
import { toast } from "react-toastify"

export function useTrackViewCart() {
  const trackViewCart = useTrackingViewCart()

  useEffect(() => {
    if (trackViewCart.data) {
      dispatchAdobeEvent({ type: "view_cart", data: trackViewCart.data })
    }
  }, [trackViewCart.data])
}

export function useDispatchTrackCartMutation() {
  return (
    type: "add_to_cart" | "remove_from_cart",
    props: {
      quantity: number
      sku: string
      oldViewCart?: ViewCartDispatchEventData
    }
  ) => {
    const data = toAdobeEventCartEntryMutationData(props)
    if (data) {
      dispatchAdobeEvent({ type, data })
    }
  }
}

export function useDispatchTrackCartDeliveryInformation() {
  const trackViewCart = useTrackingViewCart()

  return () =>
    trackViewCart.refetch().then(({ data }) => {
      dispatchAdobeEvent({ type: "cart_delivery_information", data })
    })
}

export function toAdobeEventCartEntryMutationData(props: {
  quantity: number
  sku: string
  oldViewCart?: ViewCartDispatchEventData
}) {
  const item = props.oldViewCart?.items.find((entry) => entry.item_id === props.sku)

  if (!props.oldViewCart || !item) {
    return undefined
  }

  return {
    value: getCartEntryMutationValue(props.quantity, item.price),
    currency: "EUR",
    items: [
      {
        ...item,
        quantity: props.quantity,
      },
    ],
  }
}

export function useTrackCartToastNotifications() {
  useEffect(() => {
    return toast.onChange((item) => {
      if (item.id === "minimal-order-value" && item.status === "added") {
        dispatchAdobeEvent({ type: "cart_toast_notification", data: { user_selected_element: "minimal-order-value" } })
      }

      if (item.id === "maximum-order-value" && item.status === "added") {
        dispatchAdobeEvent({ type: "cart_toast_notification", data: { user_selected_element: "maximum-order-value" } })
      }
    })
  }, [])
}
