import { supportedLanguages } from "config/formula"
import { pages } from "config/pages"
import type { PagesConfig } from "config/types"
import { useMemo } from "react"
import { useTranslation } from "react-i18next"

export function usePagesConfig() {
  const { i18n } = useTranslation()

  return useMemo(() => {
    const pagesConfig: PagesConfig = { ...pages }

    if (supportedLanguages.length > 1) {
      const lang = i18n.language.substring(0, 2)

      for (const [key, page] of Object.entries(pagesConfig)) {
        pagesConfig[key as keyof PagesConfig] = {
          ...page,
          relativeUrl: `/${lang}${page.relativeUrl}`,
          path: `/${lang}/${page.path}`,
        }
      }
    }

    return pagesConfig
  }, [i18n.language])
}
