import { datadogRum } from "@datadog/browser-rum"
import { formulaConfig } from "config/formula"
import { globalConfig } from "config/global"

export function initializeDatadogRum() {
  if (globalConfig.isLocalhost) {
    return
  }

  datadogRum.init({
    applicationId: getDatadogRumApplicationId(),
    clientToken: getDatadogRumClientToken(),
    env: globalConfig.env,
    sessionReplaySampleRate: 100,
    sessionSampleRate: 100,
    service: "checkout-frontend",
    site: "datadoghq.eu",
    version: VERSION,
    trackUserInteractions: true,
    defaultPrivacyLevel: "mask-user-input",
    compressIntakeRequests: true,
    allowedTracingUrls: [
      formulaConfig.API_GATEWAY_URL,
      formulaConfig.CHECKOUT_URL,
      formulaConfig.MYACCOUNT_URL,
      formulaConfig.SHOPFRONT_URL,
    ],
  })
}

const datadogRumConfig = {
  acc: {
    applicationId: "6646f64f-340a-4b3b-8c2d-f6b003e828b4",
    clientToken: "pub93d00c44737f268f47555836daadec8f",
  },
  gamma_nl: {
    applicationId: "62d9eaa5-9e8e-4ae5-afdd-336372a51890",
    clientToken: "pub03a8652403c77995e6c6b25df532083e",
  },
  gamma_be: {
    applicationId: "0043ee95-d09d-400e-b893-d86c7dc10653",
    clientToken: "pub6d9a694be06accbafe81e0e53f631922",
  },
  karwei_nl: {
    applicationId: "ffbc60a5-1c29-4c4f-b380-c764cca47181",
    clientToken: "pub58e6f2ae750409dbe5ff3e1ec16aaf73",
  },
} as const

function getDatadogRumApplicationId() {
  if (globalConfig.isAcceptance || globalConfig.isLocalhost) {
    return datadogRumConfig.acc.applicationId
  }

  if (globalConfig.isProduction) {
    if (formulaConfig.brand === "gamma" && formulaConfig.country === "NL") {
      return datadogRumConfig.gamma_nl.applicationId
    }
    if (formulaConfig.brand === "gamma" && formulaConfig.country === "BE") {
      return datadogRumConfig.gamma_be.applicationId
    }
    if (formulaConfig.brand === "karwei") {
      return datadogRumConfig.karwei_nl.applicationId
    }
  }

  throw new Error("Could not determine Datadog RUM applicationId")
}

function getDatadogRumClientToken() {
  if (globalConfig.isAcceptance || globalConfig.isLocalhost) {
    return datadogRumConfig.acc.clientToken
  }

  if (globalConfig.isProduction) {
    if (formulaConfig.brand === "gamma" && formulaConfig.country === "NL") {
      return datadogRumConfig.gamma_nl.clientToken
    }
    if (formulaConfig.brand === "gamma" && formulaConfig.country === "BE") {
      return datadogRumConfig.gamma_be.clientToken
    }
    if (formulaConfig.brand === "karwei") {
      return datadogRumConfig.karwei_nl.clientToken
    }
  }

  throw new Error("Could not determine Datadog RUM clientToken")
}
