import type { PaymentResponse } from "api/generated/checkout"
import type { BancontactMobilePaymentResponse } from "types"
import type { PaymentResultHandler } from "../../types"
import { handleRedirect } from "../handleRedirect"

export function handlePaymentResultRedirectShopper({
  paymentResponse,
  navigate,
  pagesConfig,
  billingCountry,
  deviceType,
  currencyIso,
  cartUid,
  logger,
}: PaymentResultHandler) {
  if (checkIsBancontactMobilePayment(paymentResponse)) {
    if (paymentResponse.amountRemaining === undefined) {
      throw new Error("paymentResponse.amountRemaining was undefined")
    }
    const params = new URLSearchParams({
      paymentData: paymentResponse.paymentData,
      qrCodeData: paymentResponse.action?.qrCodeData,
      url: paymentResponse.action?.url,
      cartUid: cartUid || "",
      amountRemaining: Math.floor(paymentResponse.amountRemaining).toString(),
      billingCountry: billingCountry ?? "",
      currencyIso: currencyIso ?? "",
      deviceType: deviceType?.toString() || "",
    })
    logger("payment-bancontact-mobile")
    navigate(`${pagesConfig.bancontact.relativeUrl}?${params}`)
  } else if (!paymentResponse.action) {
    throw new Error("Missing action data for payment")
  } else {
    logger("payment-redirect")
    handleRedirect(paymentResponse.action)
  }
}

export function checkIsBancontactMobilePayment(
  paymentResponse: PaymentResponse
): paymentResponse is BancontactMobilePaymentResponse {
  const { action } = paymentResponse

  if (!action) {
    return false
  }

  return action.paymentMethodType === "bcmc_mobile"
}
