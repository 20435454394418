import type { CartMutationResponse, CartResponse } from "features/cart/types"

function isCartMutationResponse(
  cartResponse: CartResponse | CartMutationResponse
): cartResponse is CartMutationResponse {
  return "cart" in cartResponse && !("uid" in cartResponse)
}

// temporary solution
// cart updates result into a response with the cart and cartEntryErrors separated
// e.g. { cart: {}, cartEntryErrors: {} }
// we agreed the endpoint should do that by itself but decided to not make that change
// right now and do it temporarily on the frontend in order to save development time
export function flattenToCart(cartResponse: CartResponse | CartMutationResponse) {
  if (isCartMutationResponse(cartResponse)) {
    const mappedCartMutationResponse: CartResponse = {
      ...cartResponse.cart,
      cartErrors: cartResponse.cartErrors,
      cartEntryErrors: cartResponse.cartEntryErrors,
    }

    return mappedCartMutationResponse
  }

  return cartResponse
}
