import type { IFullCheckoutFormValues } from "features/checkout/types"
import type { TFunction } from "i18next"
import { z } from "zod"

export function schema(values: IFullCheckoutFormValues["payment"], t: TFunction<["checkout" | "checkout-common"]>) {
  return z
    .object({
      type: z.enum(
        [
          "applepay",
          "ideal",
          "scheme",
          "paypal",
          "klarna",
          "klarna_account",
          "giftcard",
          "bcmc_mobile",
          "bcmc",
          "c_pos",
          "loyalty",
          "none",
        ],
        { errorMap: () => ({ message: t("checkout-common:ChoosePaymentMethod") }) }
      ),
      ...(values?.type === "ideal" && {
        id: z
          .string({ required_error: t("checkout-common:ChooseYourBank") })
          .min(1, t("checkout-common:ChooseYourBank")),
      }),
      ...(values?.type === "scheme" && {
        brand: z.string().optional(),
        holderName: z.string({ required_error: t("checkout-common:FillInCardHolderName") }),
        encryptedCardNumber: z.string({ required_error: t("checkout:SecuredFieldInvalid.encryptedCardNumber") }),
        encryptedExpiryMonth: z.string({ required_error: t("checkout:SecuredFieldInvalid.encryptedExpiryMonth") }),
        encryptedExpiryYear: z.string({ required_error: t("checkout:SecuredFieldInvalid.encryptedExpiryYear") }),
        encryptedSecurityCode: z.string().optional(),
      }),
      ...(values?.type === "giftcard" && {
        brand: z.string({ required_error: t("checkout:ChooseYourGiftCard") }),
        encryptedCardNumber: z.string({ required_error: t("checkout:SecuredFieldInvalid.encryptedCardNumber") }),
        encryptedSecurityCode: z.string({
          required_error: t("checkout:SecuredFieldInvalid.encryptedSecurityCode"),
        }),
        additionalData: z.object({ testBalanceOverride: z.string().optional() }).optional(),
      }),
      ...(values?.type === "bcmc" && {
        brand: z.string().optional(),
        multiPartReference: multiPartReference(t),
        encryptedCardNumber: z.string({ required_error: t("checkout:SecuredFieldInvalid.encryptedCardNumber") }),
        encryptedExpiryMonth: z.string({ required_error: t("checkout:SecuredFieldInvalid.encryptedExpiryMonth") }),
        encryptedExpiryYear: z.string({ required_error: t("checkout:SecuredFieldInvalid.encryptedExpiryYear") }),
      }),
      ...(values?.type === "applepay" && {
        applePayToken: z.string().optional(),
      }),
    })
    .superRefine((values, ctx) => {
      const brand = values.brand as string
      const brandsWithoutCvc = ["bcmc", "maestro"]
      const isCvcRequired =
        values.type === "scheme" && !brandsWithoutCvc.includes(brand) && !values.encryptedSecurityCode
      if (isCvcRequired) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: t("checkout:SecuredFieldInvalid.encryptedSecurityCode"),
        })
      }
    })
}

export function multiPartReference(_t: TFunction<["checkout" | "checkout-common"]>) {
  return z.string().min(1)
}
