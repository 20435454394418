import { formulaConfig } from "config/formula"
import Cookies from "js-cookie"
import { useEffect } from "react"
import { useTranslation } from "react-i18next"

export function useSetLanguageCookieForBackend() {
  const { i18n } = useTranslation()

  useEffect(() => {
    Cookies.set(`${formulaConfig.cookiePrefix}-LOCALE`, i18n.language, {
      expires: 365,
      domain: formulaConfig.baseDomain,
      sameSite: "strict",
    })
  }, [i18n.language])
}
