import { formulaConfig } from "config/formula"
import { toast } from "react-toastify"
import type { PaymentResultHandler } from "../../types"
import { createConfirmationPageUrl } from "../createUrl"

export function handlePaymentResultReceived({
  paymentResponse,
  form,
  i18n,
  navigate,
  pagesConfig,
  billingCountry,
  cartUid,
  logger,
}: PaymentResultHandler) {
  const { amountRemaining, confirmationData, multiPartPayment, multiPartReference } = paymentResponse

  if (multiPartPayment && typeof amountRemaining === "number" && amountRemaining > 0) {
    const paymentBrand = form.getValues().payment?.brand ?? ""
    const brand = i18n.t("checkout:PaymentMethod", {
      context: paymentBrand,
      giftcardName: formulaConfig.theme.giftcardName,
    })
    toast.success(i18n.t("checkout:PaymentCardAddedSuccess", { brand }))

    logger("payment-multipart")
    form.setValue("payment", undefined)

    const params = new URLSearchParams({
      amountRemaining: amountRemaining.toString() ?? "",
      multiPartReference: multiPartReference || "",
      billingCountry: billingCountry || "",
      cartUid: cartUid || "",
    })

    navigate(`${pagesConfig.multipartPayment.relativeUrl}?${params}`)
  } else if (!confirmationData) {
    throw new Error("Missing confirmationData for payment")
  } else {
    logger("payment-confirmation")
    navigate(createConfirmationPageUrl(confirmationData, pagesConfig.confirmation.relativeUrl), {
      replace: true,
    })
  }
}
