import { supportedLanguages } from "config/formula"
import type { SupportedLanguage } from "types"

/**
 * get first part of a language/locale
 *
 * @example getLanguageCode("fr-BE") => fr
 */
export function getLanguageCode(language: string): SupportedLanguage {
  return language.substring(0, 2).toLowerCase() as SupportedLanguage
}

/**
 * Add a language code to a pathname if language code is a support language
 *
 * @example getPathnameWithLanguageCode("/n/cart", "fr", ["fr", "nl"]) => /n/fr/cart
 */
export function getPathnameWithLanguageCode(pathname: string, language: string) {
  const prefix = getLanguageCode(language)
  return "/n" + pathname.replace(getMultiLanguageCodeRegex(), `${prefix}`)
}

/**
 *
 * @example hasLanguageCode("/n/nl/cart", ["nl", "fr"]) => true
 */
export function hasLanguageCode(pathname: string): boolean {
  return !!pathname.match(getMultiLanguageCodeRegex())
}

/**
 *
 * @example getMultiLanguageCodeRegex(["nl", "fr"]) => \(nl|fr)\
 */
export function getMultiLanguageCodeRegex(): RegExp {
  const multiLanguagePrefixes = supportedLanguages.join("|")
  return new RegExp(`(${multiLanguagePrefixes})`)
}
