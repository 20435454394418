import { HOME_DELIVERY } from "config/constants"
import type {
  AvailableDelivery,
  CartSummaryData,
  CartSummaryDataResponse,
  DeliveryMethod,
} from "features/checkout/types"

function mapDeliveryMethods(availableDeliveries: AvailableDelivery[]): DeliveryMethod[] {
  return availableDeliveries
    .map((deliveryMethod) => ({ type: deliveryMethod.type, price: deliveryMethod.deliveryCost.value }))
    .sort((deliveryMethod) => (deliveryMethod.type === HOME_DELIVERY ? -1 : 1))
}

export function mapCartSummary(cartSummary: CartSummaryDataResponse): CartSummaryData {
  const mappedCartSummary: CartSummaryData = {
    ...cartSummary,
    deliveryMethods: mapDeliveryMethods(cartSummary.availableDeliveries),
  }
  return mappedCartSummary
}
