import { gamma_nl as theme } from "@intergamma/theme"
import { GAMMA, NL } from "config/constants"
import { globalConfig } from "config/global"
import type { BaseFormulaConfig } from "config/types"

export type GammaNLBaseDomain = "gamma.nl" | "acceptatie.gamma.nl"
export type GammaNLCookiePrefix = "prd-gamma-nl" | "acc-gamma-nl"

const baseDomain = globalConfig.isAcceptance ? "acceptatie.gamma.nl" : "gamma.nl"

export const gamma_nl: BaseFormulaConfig<GammaNLBaseDomain, GammaNLCookiePrefix> = {
  baseDomain,
  brand: GAMMA,
  brandName: "GAMMA",
  contentfulCartDeliveryInformationEntryId: "3KFVax2cVhe2QpEVvRpDZE",
  contentfulConfirmationDifmInfoEntryId: "63dLokCZbWM00WZWqgbocM",
  contentfulContentDeliveryApiAccessToken: "264fa5643e4db3af05892ce1cec30e1880b98e082f9e0e3074590b6d67c508a7",
  contentfulContentPreviewApiAccessToken: "c191cdfede7ea0271748a7960808e00dde77857a57663014c151aea7eadbd9c8",
  contentfulNotificationCheckoutPageEntryId: "6LFjDQj2FGekVemF6Zntsb",
  contentfulNotificationCartPageEntryId: "d1U9DlFk9NxcL2Tw20P92",
  contentfulSpaceId: "7k5qhlbm8iou",
  contentfulToken: "264fa5643e4db3af05892ce1cec30e1880b98e082f9e0e3074590b6d67c508a7",
  cookiePrefix: globalConfig.isAcceptance ? "acc-gamma-nl" : "prd-gamma-nl",
  country: NL,
  defaultLocale: "nl",
  formulaCountry: "NL",
  id: "gamma_nl",
  locales: ["nl"],
  loyaltyCardPrefixes: ["2611", "2615", "2616"],
  suggestionApiKey: "c2c01a73654f317715999f4da06aaaec",
  theme,
  // @see serverConfig.proxy in vite.config.ts
  API_GATEWAY_URL: globalConfig.isLocalhost ? "/api-gateway/gamma_nl" : `https://api.${baseDomain}`,
  CHECKOUT_URL: globalConfig.isLocalhost ? `/checkout/gamma_nl` : `https://kassa.${baseDomain}`,
  MYACCOUNT_URL: globalConfig.isLocalhost ? "/myaccount/gamma_nl" : `https://mijn.${baseDomain}`,
  SHOPFRONT_URL: globalConfig.isLocalhost ? "/shopfront/gamma_nl" : `https://www.${baseDomain}`,
}
