import * as formatters from "@intergamma/formatters"
import { formulaConfig } from "config/formula"
import { getMultiLanguageCodeRegex } from "helpers/i18n"
import i18next from "i18next"
import LanguageDetector from "i18next-browser-languagedetector"
import i18nextHttpBackend from "i18next-http-backend"
import { log } from "lib/datadog-logging"
import { initReactI18next } from "react-i18next"

const languageDetector = new LanguageDetector()
languageDetector.addDetector({
  lookup: () => {
    const multiLangueRegex = getMultiLanguageCodeRegex()
    const language = window.location.pathname.match(multiLangueRegex)

    return language ? `${language[0]}-${formulaConfig.country}` : undefined
  },
  name: "pathLanguagePrefix",
})

languageDetector.addDetector({
  lookup: () => {
    let language
    if (navigator.languages.length > 0) {
      language = navigator.languages[0]
    } else if (navigator.language) {
      language = navigator.language
    }

    return language ? `${language.substring(2, 0)}-${formulaConfig.country}` : undefined
  },
  name: "browserLanguage",
})

export function initializeI18next() {
  i18next.on("onFailedLoading", (locale, namespace, message) => {
    log.warn(`Failed loading translation file: ${namespace}`, {
      errorDetails: { locale, message, namespace },
    })
  })

  i18next
    .use(i18nextHttpBackend)
    .use(languageDetector)
    .use(initReactI18next)
    .init({
      backend: {
        loadPath:
          formulaConfig.brand === "karwei"
            ? `/n/locales/karwei_{{lng}}/{{ns}}.json?v=${VERSION}`
            : `/n/locales/{{lng}}/{{ns}}.json?v=${VERSION}`,
      },
      interpolation: {
        defaultVariables: { ...formulaConfig.theme, ...formulaConfig },
        escapeValue: false,
        format: (value: any, format: any, lng: any) => {
          if (!format || !(format in formatters)) {
            return value
          }

          return (formatters as Record<string, Function>)[format](value, lng, i18next)
        },
      },
      missingInterpolationHandler: (text: any) => {
        // DBA-1077: this should be logged as error instead of warning, but there is an issue
        // that causes to fire this handler on all translation keys when a translation file failed to load
        // this is flooding the DataDog error logs so it has been brought back to a console warning (which DD does not capture)
        // eslint-disable-next-line no-console
        console.warn(`Missing value for translation interpolation. Translation content: ${text}`)
      },
      missingKeyHandler: (lng: any, ns: any, key: any, fallbackValue: any) => {
        // DBA-1077: this should be logged as error instead of warning, but there is an issue
        // that causes to fire this handler on all translation keys when a translation file failed to load
        // this is flooding the DataDog error logs so it has been brought back to a console warning (which DD does not capture)
        // eslint-disable-next-line no-console
        console.warn(
          `Missing ${lng} translation with key "${key}" and namespace "${ns}". Falling back to: "${fallbackValue}"`
        )
      },
      ns: ["checkout-common"],
      react: {
        transKeepBasicHtmlNodesFor: ["strong", "i", "p", "b"],
        transSupportBasicHtmlNodes: true,
        transWrapTextNodes: "span",
      },
      // Enable this in order to debug translation issues.
      debug: false,
      saveMissing: true,
      defaultNS: "checkout-common",
      supportedLngs: [...formulaConfig.locales, "nl", "fr"],
      fallbackLng: formulaConfig.defaultLocale,
      detection: {
        order: formulaConfig.locales.length > 1 ? ["pathLanguagePrefix", "browserLanguage"] : ["navigator"],
      },
    })
}

export default i18next
